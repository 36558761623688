import {DecimalPipe} from '@angular/common'
import {Component, Input} from '@angular/core'
import {Kalpylator, Property} from '@sparbanken-syd/kalpylator'
import {CopyComponent} from '../98-copy/copy.component'

@Component({
  selector: 'spb-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['../92-loans/loans.component.scss'],
  imports: [CopyComponent, DecimalPipe]
})
export class PropertiesComponent {

  @Input() kalpylator: Kalpylator = new Kalpylator()

  public copy = (): string => {
    let res = ''
    res += 'Bostad\tDriftskostnad\tFastighetsavgift\tAvgift/Hyra\tSumma\n'
    this.kalpylator.properties.forEach((property: Property) => {
      res += `${property.propertyType}\t${property.runCost}\t${property.tax}\t${property.rent}\t${property.monthlyCost}\n`.replace('.', ',')
    })
    return res
  }
}
