import {Routes} from '@angular/router'
import {ResultComponent} from './result/result.component'

export default [
  {
    path: ':id',
    component: ResultComponent
  },
  {
    path: '**',
    component: ResultComponent
  }
] satisfies Routes