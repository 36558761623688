@if (testMode) {
  <div class="holder mat-elevation-z4">
    <mat-checkbox [formControl]="otherBuildings" color="accent"
                  ngDefaultControl>Andra boenden
    </mat-checkbox>
    <mat-checkbox [formControl]="coApplicant" color="accent" ngDefaultControl>
      Medlåntagare
    </mat-checkbox>
    <mat-checkbox [formControl]="borgo" color="accent" ngDefaultControl>Borgo
    </mat-checkbox>
    @if (type !== 'APPLICATION') {
      <button (click)="setTest()" color="accent"
              mat-stroked-button>Fyll i
      </button>
    }
    <button (click)="fillAll()" color="accent" mat-stroked-button>Allt</button>
  </div>
}