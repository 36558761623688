import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {
  Applicant,
  Child,
  Income,
  Loan,
  Property
} from '@sparbanken-syd/kalpylator'
import {Subject, takeUntil} from 'rxjs'
import {filter} from 'rxjs/operators'
import {environment} from '../../../environments/environment'
import {BorgoService} from '../../services/borgo.service'
import {ConfigService, ILogInState} from '../../services/config.service'
import {KalpService} from '../../services/kalp.service'
import {TypesService} from '../../services/types.service'


@Component({
  selector: 'spb-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  imports: [MatCheckbox, ReactiveFormsModule, MatButton]
})
export class TestComponent implements OnInit, OnDestroy {

  @Input() type: 'APPLICANTS' | 'BORGO' | 'INCOMES' | 'LOANS' | 'PROPERTIES' | 'APPLICATION' = 'BORGO'

  public borgo: FormControl<boolean> = new FormControl<boolean>(true, {nonNullable: true})

  public otherBuildings: FormControl<boolean> = new FormControl<boolean>(false, {nonNullable: true})

  public coApplicant: FormControl<boolean> = new FormControl<boolean>(true, {nonNullable: true})

  /**
   * Test functions available?
   */
  public testMode: boolean = environment.testMode

  /**
   * Use this to stop subscribing to stuff
   *
   * @private
   */
  private stop$ = new Subject<boolean>()

  private mainSub = ''

  constructor(
    private borgoService: BorgoService,
    private ts: TypesService,
    private kalpService: KalpService,
    private configService: ConfigService
  ) {
  }

  public ngOnInit(): void {
    this.borgoService.borgoPossible$
      .pipe(takeUntil(this.stop$))
      .subscribe({
        next: (possible: boolean) => this.borgo.setValue(possible, {emitEvent: false})
      })

    this.borgo.valueChanges
      .pipe(takeUntil(this.stop$))
      .subscribe({
        next: (value: boolean) => {
          this.borgoService.borgoPossible$.next(value)
        }
      })

    this.kalpService.applicantCount$
      .pipe(takeUntil(this.stop$)).subscribe(
      {
        next: (count: number) => this.coApplicant.setValue(count > 1)
      }
    )

    /**
     * Get the current user and set the test pnr to that user
     * so that we do not pollute Daniels
     */
    this.configService.logInState$
      .pipe(
        takeUntil(this.stop$),
        filter(Boolean)
      )
      .subscribe({
        next: (user: ILogInState) => {
          this.mainSub = user.sub
        }
      })
  }

  public ngOnDestroy() {
    this.stop$.next(true)
  }

  public setTest(): void {
    switch (this.type) {
      case 'BORGO':
        this.setPreconditions(this.borgo.value)
        break
      case 'APPLICANTS':
        this.setApplicants()
        break
      case 'INCOMES':
        this.setIncomes()
        break
      case 'LOANS':
        this.setLoans()
        break
      case 'PROPERTIES':
        this.setProperties(this.otherBuildings.value)
        break
      case 'APPLICATION':
        this.fillAll()
    }
  }

  public fillAll(): void {
    this.setPreconditions(this.borgo.value)
    this.setProperties(this.otherBuildings.value)
    this.setApplicants()
    this.setLoans()
    this.setIncomes()
  }

  private setPreconditions(borgo: boolean): void {
    this.borgoService.preconditions$.next({
      bottenPant: borgo,
      code220: true,
      foreignOrBox: true,
      protectedIdentity: true,
      samTaxerad: true,
      tredjemansPant: true,
      foreignCurrency: true,
      bostadsratt: true,
      aKassa: true,
      pep: true,
      thirdCountry: true,
      rentedOut: true,
      noAmortization: true,
      noCurrentBorgo: true
    })
    this.borgoService.borgoPossible$.next(borgo)
    this.borgo.setValue(borgo, {emitEvent: true})
  }

  private setApplicants(): void {
    const applicants: Applicant[] = [this.ts.getApplicant(), this.ts.getApplicant()]
    applicants[0].occupationType = 'EMPLOYED'
    applicants[0].sub = this.mainSub
    applicants[0].name = 'Anders Olsson'

    applicants[1].occupationType = 'EMPLOYED'
    applicants[1].sub = '1959005123259'
    applicants[1].name = 'Johanna Gustavsson'

    if (!this.coApplicant.value) {
      applicants.splice(1, 1)
    }
    this.kalpService.applicants$.next(applicants)

    const children: Child[] = [this.ts.getChild(), this.ts.getChild()]
    children[0].age = 4
    children[1].age = 15
    this.kalpService.children$.next(children)
  }

  private setIncomes(): void {
    const incomes: Income[] = [this.ts.getIncome(), this.ts.getIncome()]

    Object.assign(incomes[0], {
      monthlyIncome: 28000, taxFreeIncome: 1000, studyLoan: 1250
    })
    Object.assign(incomes[1], {
      monthlyIncome: 16500, studyLoan: 1250, otherCosts: 800
    })
    if (!this.coApplicant.value) {
      incomes.splice(1, 1)
    }
    this.kalpService.incomes$.next(incomes)
  }

  private setLoans(): void {
    const loans: Loan[] = [
      {
        amount: 25000,
        loanType: 'BLANCO',
        id: '1',
        type: 'loans',
        mortgage: 0,
        interestRate: 0.082
      },
      {
        amount: 250000,
        loanType: 'BORGEN',
        id: '2',
        type: 'loans',
        mortgage: 1000,
        interestRate: 0.082
      },
      {
        amount: 1.2 * 1000 * 1000,
        loanType: 'MORTGAGE',
        id: '1',
        type: 'loans',
        mortgage: 10,
        interestRate: 0.0225,
        keep: true,
        new: true
      }
    ]
    this.kalpService.loans$.next(loans)
  }

  private setProperties(otherBuildings: boolean): void {
    const properties: Property[] = [
      {
        type: 'properties',
        id: '1',
        propertyType: 'VILLA',
        primary: true,
        loanAmount: 3000 * 1000 * 0.85,
        marketValue: 3000 * 1000
      },
      {
        type: 'properties',
        id: '2',
        propertyType: 'BOSTADSRATT',
        primary: false,
        keep: false,
        rent: 4000,
        loanAmount: 850 * 1000 * 0.85,
        marketValue: 850 * 1000
      },
      {
        type: 'properties',
        id: '3',
        propertyType: 'FRITIDSHUS',
        primary: false,
        keep: false,
        loanAmount: 250 * 1000 * 0.85,
        marketValue: 250 * 1000
      }
    ]

    if (!otherBuildings) {
      properties.pop()
    }
    this.kalpService.properties$.next(properties)
  }
}
