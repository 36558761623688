<div class="table mat-elevation-z5">
  <div class="header">
    <div class="column">Bostad</div>
    <div class="column">Driftskostnad</div>
    <div class="column">Fastighetsavgift</div>
    <div class="column">Avgift/Hyra</div>
    <div class="column">Summa</div>
    <div class="column center"></div>
  </div>
  @for (property of this.kalpylator.properties; track property) {
    <div class="row">
      <div class="column">{{ property.propertyType }}</div>
      <div class="column">{{ property.runCost | number: '1.0-0' : 'fr' }}</div>
      <div class="column">{{ property.tax | number: '1.0-0' : 'fr' }}</div>
      <div class="column">{{ property.rent | number: '1.0-0' : 'fr' }}</div>
      <div class="column">{{ property.monthlyCost | number: '1.0-0' : 'fr' }}
      </div>
      <div class="column center"></div>
    </div>
  }
  <div class="summary">
    <div class="column sum">Totalt</div>
    <div
      class="column right">{{ kalpylator.monthlyPropertyRunCost | number: '1.0-0' : 'fr' }}
    </div>
    <div
      class="column right">{{ kalpylator.monthlyPropertyTax | number: '1.0-0' : 'fr' }}
    </div>
    <div
      class="column right">{{ kalpylator.monthlyPropertyRent | number: '1.0-0' : 'fr' }}
    </div>
    <div
      class="column right">{{ kalpylator.monthlyPropertyCost | number: '1.0-0' : 'fr' }}
    </div>
  </div>
  <div class="copy-to-right">
    <spb-copy [copy]="copy"></spb-copy>
  </div>

</div>
