import {KeyValuePipe} from '@angular/common'
import {Component, Input, OnChanges, OnInit} from '@angular/core'
import {FormArray, FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatOption} from '@angular/material/core'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatSelect} from '@angular/material/select'
import {Car, CarType} from '@sparbanken-syd/kalpylator'
import {TypesService} from '../../../../services/types.service'

@Component({
  selector: 'spb-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss'],
  imports: [MatButton, MatFormField, MatLabel, MatSelect, ReactiveFormsModule, MatOption, MatIconButton, MatSuffix, MatIcon, KeyValuePipe]
})
export class CarsComponent implements OnInit, OnChanges {

  /**
   * The array of cars that we freely manipulate
   */
  @Input() cars: Car[] = []

  /**
   * Type of car ownership for display purposes only
   */
  public carTypeMap: Record<CarType, string> = {
    LEASE: 'Leasing',
    OWN: 'Privatägd'
  }

  public carsControls = new FormArray<FormControl<CarType>>([])


  constructor(
    private ts: TypesService
  ) {
  }

  ngOnInit(): void {
    this.carsControls = new FormArray<FormControl<CarType>>([])
    this.carsControls.valueChanges.subscribe({
      next: (val: CarType[]) => {
        val.forEach((carType: CarType, index: number) => this.cars[index].carType = carType)
      }
    })
  }

  public ngOnChanges(): void {
    this.carsControls.clear()
    this.cars.forEach((car: Car, index: number) => {
      this.addCarControl()
      this.carsControls.at(index).setValue(car.carType, {emitEvent: false})
    })
  }

  public addCar(): void {
    this.cars.push(this.ts.getCar())
    this.addCarControl()
  }

  public removeCar(index: number): void {
    this.cars.splice(index, 1)
    this.carsControls.removeAt(index)
  }

  private addCarControl(): void {
    this.carsControls.push(new FormControl<CarType>('OWN', {nonNullable: true}), {emitEvent: false})
  }
}
