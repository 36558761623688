import {Routes} from '@angular/router'
import kalpRoutes from './2-kalp/kalp-routes'
import resultRoutes from './3-result/result-routes'
import {authGuard} from './application/auth.guard'
import {
  KALP_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  RESULT_ROUTE_PATH,
  START_ROUTE_PATH
} from './application/data-types'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: KALP_ROUTE_PATH,
    children: kalpRoutes,
    canActivate: [authGuard]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadComponent: () => import('./0-login/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: START_ROUTE_PATH,
    loadComponent: () => import('./1-start/start/start.component').then(m => m.StartComponent),
    canActivate: [authGuard]
  },
  {
    path: RESULT_ROUTE_PATH,
    children: resultRoutes,
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]