import {StepperSelectionEvent} from '@angular/cdk/stepper'
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {
  MatStep,
  MatStepContent,
  MatStepLabel,
  MatStepper,
  MatStepperNext,
  MatStepperPrevious
} from '@angular/material/stepper'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {
  Applicant,
  Car,
  Child,
  Income,
  KalpDataItem,
  Loan,
  Property
} from '@sparbanken-syd/kalpylator'
import {verify} from '@sparbanken-syd/personnummer'
import {
  distinctUntilChanged,
  forkJoin,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs'
import {DataStoreItem} from 'sparbanken-syd-datastore'
import {KALP_ROUTE_PATH, RESULT_ROUTE_PATH} from '../../application/data-types'
import {TestComponent} from '../../common/test/test.component'
import {BorgoService} from '../../services/borgo.service'
import {DatastoreService, KalpStoreData} from '../../services/datastore.service'
import {InterestService} from '../../services/interest.service'
import {KalpService, TDomains} from '../../services/kalp.service'
import {TypesService} from '../../services/types.service'
import {HelpBaseComponent} from '../help/0-help-base/help-base.component'
import {PersDataComponent} from './1-pers-data/pers-data.component'
import {IncomeCostComponent} from './2-income-cost/income-cost.component'
import {NewHomeComponent} from './3-new-home/new-home.component'
import {LoansComponent} from './4-loan/loans.component'
import {PersonNummerSelect} from './application-types'

@Component({
  selector: 'spb-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
  imports: [MatStepper, MatStep, MatStepLabel, TestComponent, PersDataComponent, HelpBaseComponent, MatButton, MatStepperNext, IncomeCostComponent, MatStepperPrevious, MatStepContent, MatCheckbox, ReactiveFormsModule, NewHomeComponent, LoansComponent]
})
export class ApplicationComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * We interact with the stepper, remember that this is pretty
   * void before afterViewInit!
   */
  @ViewChild('stepper') public stepper: MatStepper = {} as any

  /**
   * Filtered values for the loans, blanco and mortgage
   */
  public blancoLoans: Loan[] = []
  public mortgageLoans: Loan[] = []
  public newProperty: Property = {} as any
  public checked = false
  /**
   * Real collections of all sorts of items
   */
  public applicants: Applicant[] = []
  public children: Child[] = []
  public incomes: Income[] = [{} as any]
  public properties: Property[] = []
  public loans: Loan[] = [] as any

  public cars: Car[] = [] as any
  /**
   * A bunch of controls to control the steps
   */
  public applicantsCtrl = new FormControl<boolean>(true, {
    nonNullable: true,
    validators: [Validators.requiredTrue]
  })
  public incomesCtrl = new FormControl<boolean>(true, {
    nonNullable: true,
    validators: [Validators.requiredTrue]
  })
  public loansCtrl = new FormControl<boolean>(true, {
    nonNullable: true,
    validators: [Validators.requiredTrue]
  })
  public propertiesCtrl = new FormControl<boolean>(true, {
    nonNullable: true,
    validators: [Validators.requiredTrue]
  })

  /**
   * Set this wile doing calculus so that we can hide
   * the stepper etc.
   */
  public calculating = false

  /**
   * Here we receive valid personnummer, when this arrives we will check
   * for available data in the backends. We also, potentially set this on
   * start. Not the 2 for applicant and co-applicant
   */
  private validPnr: ReplaySubject<PersonNummerSelect> = new ReplaySubject<PersonNummerSelect>(2)

  /**
   * Simple subject so that we can cancel all subscriptions
   * in one go.
   */
  private endSubscriptions: Subject<boolean> = new Subject<boolean>()

  constructor(
    public kalpService: KalpService,
    public borgoService: BorgoService,
    private interestService: InterestService,
    private ts: TypesService,
    private router: Router,
    private route: ActivatedRoute,
    private dataStoreService: DatastoreService
  ) {
  }


  public ngOnInit(): void {
    /**
     * Piece of shit bootstrapping, move this to a resolver
     * in the router.
     */
    this.interestService.getLatestInterest().subscribe()

    this.route.paramMap.pipe(
      mergeMap((paramMap: ParamMap) => {
        const applicants: PersonNummerSelect[] = [
          {
            personNummer: paramMap.get('applicantId') || '',
            index: 0
          },
          {
            personNummer: paramMap.get('coApplicantId') || '',
            index: 1
          }
        ]
        return of(applicants
          .filter((p: PersonNummerSelect) => p.personNummer !== '')
          .map((p: PersonNummerSelect) => {
            try {
              p.personNummer = verify(p.personNummer)[13]
            } catch {
              p.personNummer = ''
            }
            return p
          }))
      })
    ).subscribe({
      next: (applicants: PersonNummerSelect[]) => {
        const path = applicants
          .filter((p: PersonNummerSelect) => p.personNummer !== '')
          .map((p: PersonNummerSelect) => {
            this.validPnr.next(p)
            return p.personNummer
          })
        path.unshift(KALP_ROUTE_PATH)
        this.router.navigate(path).then()
      }
    })

    /**
     * Set all items as they arrive from the KalpService, does not support filtering
     * yet.
     */
    this.setSubscription<Applicant>(this.kalpService.applicants$, 'applicants')
    this.setSubscription<Child>(this.kalpService.children$, 'children')
    this.setSubscription<Income>(this.kalpService.incomes$, 'incomes')
    this.setSubscription<Car>(this.kalpService.cars$, 'cars')

    /**
     * We filter out the new loan as we calculate that.
     */
    this.kalpService.loans$
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe((loans: Loan[]) => {
        // Filter all loans that are not new property, but are new_property_extra
        // this.loans = loans.filter(loan => loan.id === 'new_property_extra' || loan.propertyId !== 'new_property')
        // Filter all loans that are not new property
        this.loans = loans.filter(loan => loan.id === 'new_property_extra' || loan.propertyId !== 'new_property')
      })


    this.kalpService.properties$
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe({
        next: (properties: Property[]) => {
          this.newProperty =
            Object.assign({}, properties.find((property: Property) => property.primary) || this.newProperty)

          this.properties = properties

          //Checks if there is any saved new property
          this.checked = !!this.newProperty.id
        }
      })

    /**
     * This unsubscribes when we die.
     */
    this.validPnr
      .pipe(
        distinctUntilChanged((previous: PersonNummerSelect, current: PersonNummerSelect) =>
          previous.personNummer === current.personNummer), // Do not check the same pnr twice in a row)

        mergeMap((data: PersonNummerSelect) =>
          forkJoin([of(data), this.dataStoreService.getUser(data.personNummer)]))
      )
      .subscribe({
        next: (res: [PersonNummerSelect, DataStoreItem[]]) => {
          if (res[0].index === 0) {
            /**
             * Reset when a new applicant (only first applicant) is selected
             */
            this.newProperty = {} as any
            this.kalpService.properties$.next([])
            this.properties = []
            this.cars = []
          }

          const kalpItems = res[1].map((item: DataStoreItem) => item.data)

          let applicant = kalpItems.find((i: KalpDataItem) => i.type === 'applicants')
          if (!applicant) {
            applicant = this.ts.getApplicant()
          }
          // Does not trigger change control
          this.applicants[res[0].index] = applicant

          this.kalpService.applicants$.next(this.applicants)

          let income = kalpItems.find((i: KalpDataItem) => i.type === 'incomes')
          if (!income) {
            income = this.ts.getIncome()
          }

          this.incomes[res[0].index] = income
          this.kalpService.incomes$.next(this.incomes)

          // If main applicant, set also the other items. Simple overwrite is good here
          if (res[0].index === 0) {
            this.children = kalpItems.filter((i: KalpDataItem) => i.type === 'children')
            this.loans = kalpItems.filter((i: KalpDataItem) => i.type === 'loans')
            this.properties = kalpItems.filter((i: KalpDataItem) => i.type === 'properties')
            this.cars = kalpItems.filter((i: KalpDataItem) => i.type === 'cars')
            // Note that this triggers changes in persData
            this.kalpService.children$.next(this.children)
            this.kalpService.loans$.next(this.loans)
            this.kalpService.properties$.next(this.properties)
            this.kalpService.cars$.next(this.cars)
          }
        }
      })
  }

  public ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe({
      next: (event: StepperSelectionEvent) => {
        this.kalpService.lastStep = event.selectedIndex
      }
    })

    this.stepper.animationDone.subscribe({
      next: () => {
        this.stepper.linear = this.kalpService.lastStep === 0
        this.stepper.selectedIndex = this.kalpService.lastStep
      }
    })


  }

  public ngOnDestroy(): void {
    this.endSubscriptions.next(true)
  }

  public checkboxChange(checked: boolean) {
    if (checked) {
      this.newProperty = this.ts.getProperty()
      this.newProperty.primary = true
      this.newProperty.friendlyName = 'Belåningsobjekt'
      this.newProperty.id = 'new_property'
      const borgoOrSparbanken: TDomains = this.borgoService.borgoPossible$.value ? 'borgo' : 'sparbanken'
      this.newProperty.runCost = this.kalpService.getRunCost(borgoOrSparbanken, this.newProperty.propertyType)
      this.properties.push(this.newProperty)
    } else {
      this.newProperty = {} as any
      this.properties = this.properties.filter(property => property.id !== 'new_property')
    }
  }

  public setApplicantsLength(length: number): void {
    while (this.incomes.length > length) {
      this.incomes.pop()
    }
    while (this.incomes.length < length) {
      this.incomes.push({} as any)
    }
    // Replace the array to trigger changes.
    this.incomes = this.incomes.map(i => i)
  }

  /**
   * We need this b/c the template breaks if we send in the
   * subject as is ??
   */
  public setPersonnummer(data: PersonNummerSelect): void {
    this.validPnr.next(data)
  }

  public next(): void {
    // Stop subscribing, we will die soon, and we do not want new values
    this.endSubscriptions.next(true)
    this.calculating = true // Hides the stepper

    this.setLoans()
    this.setProperties()
    this.kalpService.children$.next(this.children.map((c: Child) => Object.assign(this.ts.getChild(), c)))
    this.kalpService.cars$.next(this.cars.map((c: Car) => Object.assign(this.ts.getCar(), c)))

    // Incomes 'must' be tied to applicants
    this.applicants.forEach((applicant: Applicant, index: number) => {
      applicant.id = index + ''
      this.incomes[index].applicantId = applicant.id
    })

    this.kalpService.applicants$.next(this.applicants.map((a: Applicant) => Object.assign(this.ts.getApplicant(), a)))
    this.kalpService.incomes$.next(this.incomes.map((i: Income) => Object.assign(this.ts.getIncome(), i)))
    /**
     * Coming this far, we save all items we have collected.
     * We save without caring about the result and basically hope that this works.
     */
    this.saveItems().pipe(
      switchMap((res: DataStoreItem[]) => {
        // Now we have a great bunch if items stored in the database.
        // We mongle these up into a "KALP" and save the lot with an id
        // This id is passed on to the result component that loads the same
        // data, from server, and injects the shit into the KalpService
        //
        // Filter the list for data only
        const items: KalpDataItem[] = res
          .filter(i => i) // Remove undefined and nulls, mostly for testing
          .map((item: DataStoreItem) => item.data)
        let primaryApplicant: Applicant
          = items.find((ki: KalpDataItem) => ki.id === '0' && ki.type === 'applicants') as Applicant
        // This is potentially not there. Someone kan press save w/o it.
        // So to make sure we can continue we do this:
        primaryApplicant = primaryApplicant || this.ts.getApplicant()
        const children: KalpDataItem[] = items.filter((c: KalpDataItem) => c.sub === primaryApplicant.sub && c.type === 'children')
        const cars: KalpDataItem[] = items.filter((c: KalpDataItem) => c.sub === primaryApplicant.sub && c.type === 'cars')
        const loans: KalpDataItem[] = items.filter((c: KalpDataItem) => c.sub === primaryApplicant.sub && c.type === 'loans')
        const properties: KalpDataItem[] = items.filter((c: KalpDataItem) => c.sub === primaryApplicant.sub && c.type === 'properties')
        const incomes: KalpDataItem[] = items.filter((c: KalpDataItem) => c.type === 'incomes')

        // Delete sensitive data from each item not applicants
        children.concat(cars, loans, properties, incomes).forEach((item: KalpDataItem) => delete item.sub)

        // Filter the applicants
        const applicants = items
          .filter((i: KalpDataItem) => i.type === 'applicants' && i.sub)

        const saveData: KalpStoreData = {
          kalpItems: children.concat(cars, loans, properties, incomes, applicants),
          borgoPossible: this.borgoService.borgoPossible$.value,
          sub: primaryApplicant.sub
        }
        return this.dataStoreService.saveKalp(saveData)
      })).subscribe({
      next: (id: string) => {
        this.router.navigate(['/', RESULT_ROUTE_PATH, id]).then()
      }
    })
  }

  private saveItems(): Observable<DataStoreItem[]> {
    // In the future, add Cars and pets to this array
    // Note that Applicants and Incomes are NOT in this list, they are
    // appended last.
    const itemsToSave: KalpDataItem[] =
      [...this.children, ...this.cars,
        ...this.blancoLoans, ...this.mortgageLoans,
        ...this.properties, ...this.loans]

    /**
     * For each of the items we have we save them in the database, once
     * for each applicant, we do not know if they actually belong to this
     * user but who cares.
     *
     * Iterate over the applicants and make one save for every item.
     * We get one or two arrays back that we flatten to one array
     * and fork joins em all.
     *
     * Note that we must copy the object before sending or we will
     * constantly overwrite the item in the save operation. Remember
     * that we do not really care about the result in this stage.
     *
     * Then we call save and pass the result back
     */

    const observables: Observable<DataStoreItem>[][] =
      this.applicants.map((applicant: Applicant, index: number) => itemsToSave
        // For some reason we can have empty items here. Remains
        // to figure out why, but for now I just remove them.
        .filter((item: KalpDataItem) => item.type)
        .map((item: KalpDataItem) =>
          this.dataStoreService.saveKalpItem(Object.assign({}, item), applicant.sub))
        // Add the applicant as well
        .concat([this.dataStoreService.saveKalpItem(Object.assign({}, applicant), applicant.sub)])
        // And finally attach the income for the applicant
        .concat([this.dataStoreService.saveKalpItem(Object.assign({}, this.incomes[index]), applicant.sub)]))
    return forkJoin(observables.flat())
  }

  /**
   * Set the loans
   */
  private setLoans(): void {
    this.loans.forEach((l: Loan) => {
      if (!Number.isInteger(l.mortgage)) {
        l.mortgage = -1
      }
    })

    if (this.newProperty.primary) {
      const loan: Loan = this.ts.getLoan()
      loan.amount = this.newProperty.loanAmount as number
      loan.new = true
      loan.propertyId = 'new_property'
      loan.mortgage = -1
      this.loans.push(loan)
    }

    // Continue to do this in case we open the result w/o setting an id.
    this.kalpService.loans$.next(this.loans)
  }

  private setProperties() {
    this.properties = this.properties
      .filter((p: Property) => !p.primary)

    if (this.newProperty.id) {
      // Retrieve all loans related to the property with propertyId "new_property"
      const propertyLoans: Loan[] = this.loans.filter(loan => loan.propertyId === 'new_property')

      // Calculate the sum of the "amount" for these loans
      this.newProperty.mortgageRequirementDebt = propertyLoans.reduce((sum, loan) => sum + loan.amount, 0)
      this.properties.push(this.newProperty)
    }

    this.kalpService.properties$.next(this.properties.map((p: Property) => Object.assign(this.ts.getProperty(), p)))
  }

  private setSubscription = <T>(sub: Observable<T[]>, collection: keyof ApplicationComponent): void => {
    sub.pipe(takeUntil(this.endSubscriptions)).subscribe({
      next: (items: T[]) => {
        (this[collection] as unknown as T[]) = items.map((i: T) => i)
      }
    })
  }
}
