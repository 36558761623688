import {DecimalPipe} from '@angular/common'
import {Component, Input} from '@angular/core'
import {Applicant, Kalpylator} from '@sparbanken-syd/kalpylator'
import {CopyComponent} from '../98-copy/copy.component'

@Component({
  selector: 'spb-other',
  templateUrl: './other.component.html',
  styleUrls: ['../92-loans/loans.component.scss'],
  imports: [CopyComponent, DecimalPipe]
})
export class OtherComponent {

  @Input() kalpylator: Kalpylator = new Kalpylator()

  public copy = (): string => {
    let res = ''
    res = 'Låntagare\tSkatt\tStudielån\tÖvriga kostnader\n'
    this.kalpylator.applicants.forEach((applicant: Applicant, index: number) => {
      const income = this.kalpylator.incomes[index]

      res += `${applicant.name}\t${income.monthlyTax}\t${income.studyLoan}\t${income.otherCosts}\n`
        .replace('.', ',')

    })

    res += `\nBarn\t${this.kalpylator.monthlyChildrenCost}\n`
    res += `Bil\t${this.kalpylator.monthlyCarCost}\n`
    return res
  }
}
