import {LoanType, Property, PropertyType} from '@sparbanken-syd/kalpylator'

export interface LoanTypeName {
  title: string

  type: LoanType
}

export const loanTypeNames: LoanTypeName[] = [
  {
    title: 'Bolån',
    type: 'MORTGAGE'
  },
  {
    title: 'Kort- och kontokredit',
    type: 'CREDIT'
  },
  {
    title: 'Privatlån, avbetalning och SMS',
    type: 'BLANCO'
  },
  {
    title: 'Borgensåtagande',
    type: 'BORGEN'
  }
]

export interface PropertyTypeName {
  /**
   * What we display to the user
   */
  title: string

  /**
   * The type as defined
   */
  type: PropertyType
}

export const propertyTypeNames: PropertyTypeName[] = [
  {
    title: 'Villa',
    type: 'VILLA'
  },
  {
    title: 'Fritidshus',
    type: 'FRITIDSHUS'
  },
  {
    title: 'Bostadsrätt',
    type: 'BOSTADSRATT'
  },
  {
    title: 'Hyresrätt',
    type: 'HYRES'
  }
]


export const propertyTypeToName = (type: PropertyType): string => {
  let result = 'Villa'
  propertyTypeNames.forEach((t: PropertyTypeName) => {
    if (t.type === type) {
      result = t.title
    }
  })
  return result
}

/**
 * Abstraction to send a selected personNummer
 */
export interface PersonNummerSelect {
  /**
   * The personnummer to look for
   */
  personNummer: string

  /**
   * The index, 0 is assumed to be "main" others "coApplicant"
   */
  index: number
}

export interface NewPropertyWithExistingLoan extends Property {
  existingLoan?: number
}
