import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {Property} from '@sparbanken-syd/kalpylator'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {merge, startWith, Subject, takeUntil} from 'rxjs'
import {BorgoService} from '../../../services/borgo.service'
import {KalpService, TDomains} from '../../../services/kalp.service'
import {TypesService} from '../../../services/types.service'
import {StepComponent} from '../90-step/step.component'
import {PropertyComponent} from '../98-property/property.component'
import {PropertyTypeName, propertyTypeNames} from '../application-types'

@Component({
  selector: 'spb-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss'],
  imports: [ReactiveFormsModule, PropertyComponent, MatFormField, MatLabel, MatInput, MatSuffix, MatButton, FormatNumberDirective]
})
export class NewHomeComponent extends StepComponent implements OnInit, OnChanges, OnDestroy {

  @Input() property: Property | undefined = undefined

  /**
   * This is the complete list including (possibly) the main building that we hide for listing purposes.
   */
  @Input() properties: Property[] = []

  public filteredPropertyTypes: PropertyTypeName[] = propertyTypeNames.filter(p => p.type !== 'HYRES')

  public allPropertyTypes: PropertyTypeName[] = propertyTypeNames

  public form = new FormGroup({
    loanAmount: new FormControl<string | number>(0, {
      nonNullable: true,
      validators: [Validators.min(1)]
    }),
    mortgageRequirementValue: new FormControl<string | number>(0, {nonNullable: true}),
    mortgageRequirementDebt: new FormControl<string | number>(0, {nonNullable: true}),
    marketValue: new FormControl<string | number>(0, {nonNullable: true})
  })

  public propertyValidCtrl = new FormControl<boolean>(false, {
    nonNullable: true,
    validators: [Validators.requiredTrue]
  })

  public otherPropertiesValidCtrl: FormArray = new FormArray<any>([])

  private stopSubscribers$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private borgoService: BorgoService,
    private kalpService: KalpService,
    private typeService: TypesService
  ) {
    super()
    this.property = this.typeService.getProperty()
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(startWith({}))
      .subscribe({
        next: (val: any) => {
          if (this.property) {
            Object.assign(this.property, val)
          }
        }
      })

    this.form.controls.marketValue.valueChanges.subscribe({
      next: (val: string | number) => {
        if (this.form.controls.loanAmount.pristine) {
          this.form.controls.loanAmount.setValue(+val * 0.85)
        }
        if (this.form.controls.mortgageRequirementValue.pristine) {
          this.form.controls.mortgageRequirementValue.setValue(+val)
        }
      }
    })

    this.form.controls.loanAmount.valueChanges.subscribe({
      next: (val: string | number) => {
        if (this.form.controls.mortgageRequirementDebt.pristine) {
          this.form.controls.mortgageRequirementDebt.setValue(+val)
        }
      }
    })

    merge(this.form.statusChanges, this.propertyValidCtrl.statusChanges,
      this.otherPropertiesValidCtrl.statusChanges)
      .pipe(takeUntil(this.stopSubscribers$))
      .subscribe({
        next: () => {
          // This is needed to avoid "changed after check"
          setTimeout(() => {
            // TODO: FIX validator for other properties
            this.validControl.setValue(
              this.form.valid
              && this.propertyValidCtrl.valid
              // && this.otherPropertiesValidCtrl.valid
            )
          }, 1)
        }
      })
  }

  public ngOnChanges(): void {
    if (this.property?.id) {
      this.form.controls.loanAmount.addValidators(Validators.min(1))
      const propToForm = Object.assign({}, this.property)
      this.form.reset()
      this.form.patchValue(propToForm)
      // Don't know if/why we need this:
      // this.otherProperties.forEach(() => this.otherPropertiesValidCtrl.push(this.getValidControl()))
    } else {
      this.form.controls.loanAmount.setValidators([])
      this.form.reset()
    }
    this.form.updateValueAndValidity()
    // TODO: Is this still necessary? It creates problems with other loans
    // Clear the controls and add validators for each
    this.otherPropertiesValidCtrl.clear({emitEvent: false})
    this.properties
      .forEach((p: Property) => {
        const ctrl: AbstractControl = this.getValidControl()
        // Since the primary is hidden, we for this purpose assume it is valid.
        ctrl.setValue(p.primary)
        this.otherPropertiesValidCtrl.push(ctrl)
      })
  }

  public ngOnDestroy() {
    this.stopSubscribers$.next(true)
  }

  public addProperty(): void {
    const borgoOrSparbanken: TDomains = this.borgoService.borgoPossible$.value ? 'borgo' : 'sparbanken'
    const addedProperty: Property = this.typeService.getProperty()
    this.kalpService.propertyDefaults(addedProperty, borgoOrSparbanken)
    this.properties.push(addedProperty)
    this.otherPropertiesValidCtrl.push(this.getValidControl())
  }

  public removeProperty = (index: number): void => {
    this.properties.splice(index, 1)
    this.otherPropertiesValidCtrl.removeAt(index)
  }

  private getValidControl(): FormControl {
    return new FormControl<boolean>(false,
      {nonNullable: true, validators: [Validators.requiredTrue]})
  }
}
