<div class="holder">
  <button (click)="addCar()" [style.margin-bottom.px]="25" mat-stroked-button>
    Lägg till bil
  </button>
  @for (car of carsControls.controls; let i = $index; track car) {
    <div class="cars">
      <div class="car">
        <mat-form-field>
          <mat-label>Typ av bilägande</mat-label>
          <mat-select [formControl]="car" ngDefaultControl>
            @for (ct of carTypeMap | keyvalue; track ct.key) {
              <mat-option [value]="ct.key">{{ ct.value }}</mat-option>
            }
          </mat-select>
          <button (click)="removeCar(i)" class="form-margin" color="warn"
                  mat-icon-button matSuffix>
            <mat-icon
              aria-hidden="false" aria-label="Ta bort" color="warn"
              fontIcon="delete"></mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  }
</div>
