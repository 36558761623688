<div class="holder">
  @if (!calculating) {
    <mat-stepper #stepper linear="true"
                 orientation="vertical">
      <mat-step [stepControl]="applicantsCtrl">
        <ng-template matStepLabel>Låntagare</ng-template>
        <div class="stepper-content-holder">
          <spb-test type="APPLICANTS"></spb-test>
          <spb-pers-data (applicantLength)="setApplicantsLength($event)"
                         (validPnr)="setPersonnummer($event)"
                         [applicants]="applicants"
                         [cars]="cars"
                         [children]="children"
                         [validControl]="applicantsCtrl"
                         class="stepper-content">
          </spb-pers-data>
          <spb-help-base section="pers-data"></spb-help-base>
        </div>
        <div class="stepper-buttons">
          <button
            color="primary" mat-raised-button matStepperNext="button">Nästa
          </button>
          <div class="spb-filler"></div>
          <button (click)="next()"
                  [disabled]="applicantsCtrl.invalid" mat-raised-button
          >Beräkna
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="incomesCtrl">
        <ng-template matStepLabel>Inkomster/Kostnader</ng-template>
        <div class="stepper-content-holder">
          <spb-test type="INCOMES"></spb-test>
          <spb-income-cost [incomes]="incomes"
                           [validControl]="incomesCtrl"></spb-income-cost>
          <spb-help-base section="income-cost"></spb-help-base>
        </div>
        <div class="stepper-buttons">
          <button class="form-margin" mat-raised-button matStepperPrevious>
            Tillbaka
          </button>
          <button [disabled]="incomesCtrl.invalid"
                  color="primary"
                  mat-raised-button matStepperNext="button">Nästa
          </button>
          <div class="spb-filler"></div>
          <button
            (click)="next()"
            [disabled]="incomesCtrl.invalid"
            mat-raised-button
          >Beräkna
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="propertiesCtrl">
        <ng-template matStepLabel>Boende</ng-template>
        <ng-template matStepContent>
          <div class="stepper-content-holder">
            <spb-test type="PROPERTIES"></spb-test>
            <div class="stepper-content">
              <mat-checkbox
                (change)="checkboxChange($event.checked)"
                [checked]="checked"
                color="primary"
                ngDefaultControl>Belåningsobjekt
              </mat-checkbox>
              <spb-new-home
                [properties]="properties"
                [property]="newProperty"
                [validControl]="propertiesCtrl"
              ></spb-new-home>
            </div>
            <spb-help-base section="new-home"></spb-help-base>
          </div>
          <div class="stepper-buttons">
            <button class="form-margin" mat-raised-button matStepperPrevious>
              Tillbaka
            </button>
            <button
              [disabled]="propertiesCtrl.invalid"
              color="primary" mat-raised-button matStepperNext="button">Nästa
            </button>
            <div class="spb-filler"></div>
            <button
              (click)="next()"
              [disabled]="propertiesCtrl.invalid"
              mat-stroked-button
            >Beräkna
            </button>
          </div>
        </ng-template>
      </mat-step>
      <mat-step [stepControl]="loansCtrl">
        <ng-template matStepLabel>Befintliga lån</ng-template>
        <ng-template matStepContent>
          <div class="stepper-content-holder">
            <spb-test type="LOANS"></spb-test>
            <div class="stepper-content">
              <div>
                <h3>Bolån</h3>
                <spb-loans
                  [borgo]="borgoService.borgoPossible$.value"
                  [housingLoan]="true"
                  [loans]="loans"
                  [properties]="properties"
                  [validControl]="loansCtrl"
                ></spb-loans>
              </div>
              <div>
                <h3>{{ borgoService.borgoPossible$.value ? 'Andra krediter och borgensåtagande' : 'Privatlån, konto- & kortkrediter' }}</h3>
                <spb-loans
                  [borgo]="borgoService.borgoPossible$.value"
                  [loans]="loans"
                  [properties]="properties"
                  [validControl]="loansCtrl"
                ></spb-loans>
              </div>
            </div>
            <spb-help-base section="loan"></spb-help-base>
          </div>
          <div class="stepper-buttons">
            <button class="form-margin" mat-raised-button matStepperPrevious>
              Tillbaka
            </button>
            <div class="spb-filler"></div>
            <button
              (click)="next()"
              color="primary"
              mat-raised-button matStepperNext
            >Beräkna
            </button>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  }
</div>
