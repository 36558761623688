import {AsyncPipe} from '@angular/common'
import {Component, inject} from '@angular/core'
import {BorgoService} from '../../../services/borgo.service'

@Component({
  selector: 'spb-pers-data-help',
  templateUrl: './pers-data-help.component.html',
  styleUrls: ['../help.scss'],
  imports: [
    AsyncPipe
  ]
})
export class PersDataHelpComponent {
  public borgoService = inject(BorgoService)
}
