import {Component, Input, OnChanges} from '@angular/core'
import {FormArray, FormControl, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {Loan, Property} from '@sparbanken-syd/kalpylator'
import {StepComponent} from '../90-step/step.component'
import {LoanComponent} from '../99-loan/loan.component'

@Component({
  selector: 'spb-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss'],
  imports: [LoanComponent, MatButton]
})
export class LoansComponent extends StepComponent implements OnChanges {
  @Input() loans: Loan[] = []

  @Input() properties: Property[] = []

  @Input() housingLoan = false

  /**
   * Show Borgo help or not
   */
  @Input() borgo: boolean | null = false

  public validControls: FormArray = new FormArray<any>([])


  public ngOnChanges(): void {
    this.loans.forEach(() => {
      this.validControls.push(this.getValidControl())
    })
    this.setValidator(this.validControls)

  }

  public addLoan(): void {
    if (this.housingLoan) {
      this.loans.push({amount: 0, mortgage: '', loanType: 'MORTGAGE'} as any)
    } else {
      this.loans.push({amount: 0, mortgage: ''} as any)
    }
    this.validControls.push(this.getValidControl())
  }

  public removeLoan = (index: number): void => {
    this.loans.splice(index, 1)
    this.validControls.removeAt(index)
  }

  private getValidControl(): FormControl {
    return new FormControl<boolean>(false,
      {nonNullable: true, validators: [Validators.requiredTrue]})
  }
}
