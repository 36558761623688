<div class="holder mat-elevation-z5">
  <div class="items">
    <div class="item big">
      <div class="title">Belåningsgrad</div>
      <div
        class="value">{{ kalpylator.loanDegree * 100 | number : '1.0-0' : 'fr' }}
        %
      </div>
    </div>
    <div class="item">
      <div class="title">Amorteringsgrundande skuld</div>
      <div
        class="value">{{ kalpylator.mortgageRequirementDebt | number : '1.0-0' : 'fr' }}
        kr
      </div>
    </div>
    @if (kalpylator.mortgageRequirementDebt !== kalpylator.newLoanTotalAmount) {
      <div class="item small">
        <div class="title">(Nya lån</div>
        <div
          class="value">{{ kalpylator.newLoanTotalAmount | number : '1.0-0' : 'fr' }}
          kr)
        </div>
      </div>
    }
    <div class="item">
      <div class="title">Amorteringsgrundande värde</div>
      <div
        class="value">{{ kalpylator.mortgageRequirementValue | number : '1.0-0' : 'fr' }}
        kr
      </div>
    </div>
    @if (kalpylator.marketValue !== kalpylator.mortgageRequirementValue) {
      <div class="item small">
        <div class="title">(Bostadens värde</div>
        <div
          class="value">{{ kalpylator.marketValue | number : '1.0-0' : 'fr' }}
          kr)
        </div>
      </div>
    }
    <div [style.margin-top.px]="10" class="item big">
      <div class="title">Skuldkvot</div>
      <div class="value">{{ kalpylator.debtQuota | number : '1.0-2' : 'fr' }}
      </div>
    </div>
    <div class="item">
      <div class="title">Skuldkvotsgrundande kreditbelopp</div>
      <div
        class="value">{{ kalpylator.totalMortgageLoanAmount | number : '1.0-0' : 'fr' }}
        kr
      </div>
    </div>
    <div class="item">
      <div class="title">Bruttoinkomst</div>
      <div
        class="value">{{ kalpylator.annualTotalIncome | number : '1.0-0' : 'fr' }}
        kr / år
      </div>
    </div>
  </div>
  <spb-copy [copy]="copy"></spb-copy>
</div>
