import {Component, Inject, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {Kalpylator} from '@sparbanken-syd/kalpylator'
import {LoansComponent} from '../92-loans/loans.component'
import {PropertiesComponent} from '../93-properties/properties.component'
import {ApplicantsComponent} from '../94-applicants/applicants.component'
import {LtiComponent} from '../96-lti/lti/lti.component'

@Component({
  selector: 'spb-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.scss'],
  imports: [MatDialogTitle, MatDialogContent, ApplicantsComponent, LoansComponent, PropertiesComponent, LtiComponent, MatDialogActions, MatButton, MatDialogClose]
})
export class DetailDialogComponent implements OnInit {

  public kalpylator: Kalpylator = new Kalpylator()

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }

  ngOnInit(): void {
    this.kalpylator = this.data.kalpylator
  }

}
