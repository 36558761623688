import {Injectable} from '@angular/core'
import {FormControl} from '@angular/forms'
import {BehaviorSubject} from 'rxjs'
import {TypesService} from './types.service'

export type BorgoKeys =
  'code220'
  | 'samTaxerad'
  | 'bottenPant'
  | 'tredjemansPant'
  | 'protectedIdentity'
  | 'foreignOrBox'
  | 'foreignCurrency'
  | 'bostadsratt'
  | 'aKassa'
  | 'pep'
  | 'thirdCountry'
  | 'rentedOut'
  | 'noAmortization'
  | 'noCurrentBorgo'

export type BorgoPreconditions = Record<BorgoKeys, boolean | null | FormControl>

@Injectable({
  providedIn: 'root'
})
export class BorgoService {

  public borgoPossible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public preconditions$: BehaviorSubject<BorgoPreconditions> = new BehaviorSubject<BorgoPreconditions>(
    this.ts.getBorgoPreconditions())

  constructor(private readonly ts: TypesService) {
  }

  public reset(): void {
    this.preconditions$.next(this.ts.getBorgoPreconditions())
  }
}
