<form [formGroup]="form" [style.margin-top.px]="25">
  @if (property && property.id) {
    <div class="properties">

      <ng-container>
        <spb-property
          (valid)="propertyValidCtrl.setValue($event)"
          [primaryProperty]="true"
          [propertyTypes]="filteredPropertyTypes"
          [property]="property"></spb-property>
      </ng-container>
      <div class="property">
        <div>
          <mat-form-field>
            <mat-label>Sökt belopp</mat-label>
            <input formControlName="loanAmount" matInput name="seekedAmount"
                   spbFormatNumber
                   type="text">
            <span matTextSuffix="">kr</span>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Bostadens värde</mat-label>
            <input formControlName="marketValue" matInput name="marketValue"
                   spbFormatNumber
                   type="text">
            <span matTextSuffix="">kr</span>
          </mat-form-field>
        </div>
      </div>
      <div class="property">
        <div>
          <mat-form-field>
            <mat-label>Amorteringsgrundande värde</mat-label>
            <input formControlName="mortgageRequirementValue" matInput
                   name="mortgageRequirementValue"
                   spbFormatNumber
                   type="text">
            <span matTextSuffix="">kr</span>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Amorteringsgrundande skuld</mat-label>
            <input formControlName="mortgageRequirementDebt" matInput
                   name="mortgageRequirementDebt"
                   spbFormatNumber
                   type="text">
            <span matTextSuffix="">kr</span>
          </mat-form-field>
        </div>
      </div>
    </div>
  }
  <button (click)="addProperty()" class="margin-bottom-small"
          mat-stroked-button>Lägg till övrigt
    boende
  </button>

  <!-- Hide the primary property in this list -->
  @for (prop of properties; let i = $index; track i) {
    @if (!prop.primary) {
      <spb-property
        (valid)="otherPropertiesValidCtrl.at(i) && otherPropertiesValidCtrl.at(i).setValue($event)"
        [index]="i"
        [propertyTypes]="allPropertyTypes"
        [property]="properties[i]"
        [removeProperty]="removeProperty">
      </spb-property>
    }
  }
</form>
