import {DecimalPipe} from '@angular/common'
import {Component, Input} from '@angular/core'
import {Applicant, Kalpylator} from '@sparbanken-syd/kalpylator'
import {OtherComponent} from '../95-other/other.component'
import {CopyComponent} from '../98-copy/copy.component'

@Component({
  selector: 'spb-applicants',
  templateUrl: './applicants.component.html',
  styleUrls: ['../92-loans/loans.component.scss'],
  imports: [OtherComponent, CopyComponent, DecimalPipe]
})
export class ApplicantsComponent {

  @Input() kalpylator: Kalpylator = new Kalpylator()

  /**
   * Should this be "costs" or "incomes"?
   */
  @Input() type: 'cost' | 'income' | 'kalp-details' = 'income'

  public copy = (): string => {
    const kalpSettings = [
      {
        name: 'Kvar att leva på efter boendekostnad',
        value: (this.kalpylator.kalp + this.kalpylator.monthlyLivingExpense + this.kalpylator.monthlyChildrenCost).toFixed()
      },
      {
        name: 'Budget/levnadskostnader',
        value: (this.kalpylator.monthlyLivingExpense + this.kalpylator.monthlyChildrenCost).toFixed()
      },
      {name: 'Överskott/Underskott', value: this.kalpylator.kalp.toFixed()}
    ]

    let res = ''
    res = 'Låntagare\t'
    if (this.type === 'income') {
      res += 'Inkomst\tSkattefri inkomst\n'
    } else if (this.type === 'cost') {
      res += 'Skatt\tStudielån\tÖvriga kostnader\n'
    }

    this.kalpylator.applicants.forEach((applicant: Applicant, index: number) => {
      const income = this.kalpylator.incomes[index]
      if (this.type === 'income') {
        res += `${applicant.name}\t${income.monthlyIncome}\t${income.taxFreeIncome}\n`
          .replace('.', ',')
      } else if (this.type === 'cost') {
        res += `${applicant.name}\t${income.monthlyTax}\t${income.studyLoan}\t${income.otherCosts}\n`
          .replace('.', ',')
      } else if (this.type === 'kalp-details') {
        res = `${kalpSettings[0].name}\t${kalpSettings[0].value}\n${kalpSettings[1].name}\t${kalpSettings[1].value}` +
          `\n${kalpSettings[2].name}\t${kalpSettings[2].value}\n`.replace('.', ',')
      }
    })

    if (this.type !== 'kalp-details') {
      res += `Totalt\n`
    }
    if (this.type === 'income') {
      res += `Barnbidrag\t${this.kalpylator.monthlyChildrenIncome}\n`
    }
    return res
  }

}
