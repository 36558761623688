<div class="slider">
  <mat-form-field
    subscriptSizing="dynamic">
    <mat-label>{{ fieldTitle }}</mat-label>
    <input [formControl]="variableControl"
           matInput
           spbFormatNumber type="text">
    <span matTextSuffix="">{{ suffix }}</span>
  </mat-form-field>
  <mat-slider
    [max]="max"
    [min]="min"
    [step]="step"
    color="primary"
    discrete>
    <input (valueChange)="change($event)" [value]="variableControl.value"
           matSliderThumb>
  </mat-slider>
  <button (click)="restore()" color="primary" mat-icon-button>
    <mat-icon>autorenew</mat-icon>
  </button>
</div>
