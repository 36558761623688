import {Clipboard} from '@angular/cdk/clipboard'
import {Component, Input, OnChanges} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {
  MatExpansionPanel,
  MatExpansionPanelHeader
} from '@angular/material/expansion'
import {MatTooltip} from '@angular/material/tooltip'
import {Kalpylator} from '@sparbanken-syd/kalpylator'
import {KalpItemComponent} from '../90-kalp-item/kalp-item.component'
import {CopyComponent} from '../98-copy/copy.component'
import {
  DetailDialogComponent
} from '../99-detail-dialog/detail-dialog.component'

interface KalpDisplayItem {

  /**
   * Index to display these in order. Use sort.
   */
  index: number

  /**
   * The text to display
   */
  label: string

  /**
   * The actual value
   */
  value: number

  /**
   * A + or a - to be displayed _before_
   */
  plusMinus: string

  /**
   * If this should be treated as a summary or not
   */
  summary: boolean

  /**
   * Number of decimals on number value
   */
  decimals: string
}

@Component({
  selector: 'spb-kalp-display',
  templateUrl: './kalp-display.component.html',
  styleUrls: ['./kalp-display.component.scss'],
  imports: [MatExpansionPanel, MatExpansionPanelHeader, MatTooltip, KalpItemComponent, CopyComponent]
})
export class KalpDisplayComponent implements OnChanges {

  /**
   * The kalpylator with values inside.
   */
  @Input() kalpylator: Kalpylator = new Kalpylator()

  @Input() title = 'Borgo'

  /**
   * This is so that we can get change detection on updated
   * kalps.
   */
  @Input() kalp = 0

  public items: KalpDisplayItem[] = []

  constructor(
    private clipBoard: Clipboard,
    private dialog: MatDialog
  ) {
  }

  public ngOnChanges(): void {
    this.items.length = 0
    let i = 0

    this.items.push({
      index: i++,
      label: 'Inkomster',
      plusMinus: '+',
      summary: false,
      value: this.kalpylator.monthlyNetIncome,
      decimals: '0'
    })
    this.items.push({
      index: i++,
      label: 'Levnadsomkostnader',
      plusMinus: '-',
      summary: false,
      value: this.kalpylator.monthlyLivingExpense, decimals: '0'
    })
    this.items.push({
      index: i++,
      label: 'Barn',
      plusMinus: '-',
      summary: false,
      // For presentation purposes make sure all values are presented as positive
      value: (this.kalpylator.monthlyChildrenIncome - this.kalpylator.monthlyChildrenCost) * -1,
      decimals: '0'
    })
    this.items.push({
      index: i++, label: 'Bil', plusMinus: '-', summary: false,
      // For presentation purposes make sure all values are presented as positive
      value: this.kalpylator.monthlyCarCost, decimals: '0'
    })
    this.items.push({
      index: i++,
      label: 'Boende',
      plusMinus: '-',
      summary: false,
      value: this.kalpylator.monthlyPropertyCost,
      decimals: '0'
    })
    this.items.push({
      index: i++,
      label: 'Lån',
      plusMinus: '-',
      summary: false,
      value: this.kalpylator.monthlyLoanCost + this.kalpylator.monthlyLoanTaxReturn,
      decimals: '0'
    })
    this.items.push({
      index: i++,
      label: 'Skattereduktion',
      plusMinus: '+',
      summary: false,
      value: this.kalpylator.monthlyLoanTaxReturn,
      decimals: '0'
    })
    this.items.push({
      index: i++,
      label: '',
      plusMinus: '',
      summary: true,
      value: this.kalpylator.kalp,
      decimals: '0'
    })
    this.items.push({
      index: i++,
      label: 'Skuldkvot (LTI)',
      plusMinus: '',
      summary: false,
      value: this.kalpylator.debtQuota as number,
      decimals: '2'
    })
  }

  public copy = (): string => {
    let res = ''
    this.items
      .filter((item: KalpDisplayItem) => !item.summary)
      .forEach((item: KalpDisplayItem) => {
        let val: string | number = Number(+(item.value !== null ? item.value.toFixed(+item.decimals) : '') || '')
        if (item.plusMinus === '-') {
          val = val * -1
        }
        val = (val + '').replace('.', ',')
        res += `${item.label}\t${val}` + '\n'
      })
    return res
  }

  public open(): void {
    this.dialog.open(DetailDialogComponent, {
      data: {
        kalpylator: this.kalpylator
      }
    })
  }
}
