<mat-expansion-panel expanded>
  <mat-expansion-panel-header>
    {{ title }}
  </mat-expansion-panel-header>
  <div class="holder">
    <div matTooltip="Klicka för detaljer">
      @for (item of items; track item.index) {
        <spb-kalp-item
          (click)="open()"
          [index]="item.index"
          [label]="item.label"
          [plusMinus]="item.plusMinus"
          [summary]="item.summary"
          [decimals]="item.decimals"
          [value]="item.value">
        </spb-kalp-item>
      }
    </div>
    <spb-copy [copy]="copy"></spb-copy>
  </div>
</mat-expansion-panel>
