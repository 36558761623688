import {DecimalPipe} from '@angular/common'
import {Component, Input} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {Kalpylator, Loan} from '@sparbanken-syd/kalpylator'
import {CopyComponent} from '../98-copy/copy.component'

@Component({
  selector: 'spb-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss'],
  imports: [MatIcon, CopyComponent, DecimalPipe]
})
export class LoansComponent {

  @Input() kalpylator: Kalpylator = new Kalpylator()

  public copy = (): string => {
    let res = ''
    res += 'Belopp\tAmortering\tRänta\tMånadskostnad\tBelåningsobjekt\n'
    this.kalpylator.loans.forEach((loan: Loan) => {
      res += `${loan.amount ? loan.amount.toFixed() : ''}\t${loan.mortgage ? loan.mortgage.toFixed() : ''}` +
        `\t${loan.monthlyInterest ? loan.monthlyInterest.toFixed() : ''}\t${loan.monthlyCost ? loan.monthlyCost.toFixed() : ''}` +
        `\t${loan.new || ''}\n`.replace('.', ',')
    })
    return res
  }
}
