import {Injectable} from '@angular/core'
import {EMPTY, Observable} from 'rxjs'
import {KalpService, MONTHLY3_TEMPLATE_INTEREST} from './kalp.service'

/**
 * Describes the latest valid interest
 */
export interface LatestInterest {
  month3: number
  year1: number
  year2: number
  year3: number
  year4: number
  year5: number
  interestStartDate: string
}

@Injectable({
  providedIn: 'root'
})
export class InterestService {

  private latestInterest: LatestInterest = {} as any

  constructor(
    private kalpService: KalpService
  ) {
  }


  /**
   *  Replaced (probably temporary) fetching the latest interest from the database with fetching it hard-coded.
   */
  public getLatestInterest(): Observable<LatestInterest> {
    this.latestInterest = {
      interestStartDate: '',
      year1: MONTHLY3_TEMPLATE_INTEREST,
      year2: MONTHLY3_TEMPLATE_INTEREST,
      year3: MONTHLY3_TEMPLATE_INTEREST,
      year4: MONTHLY3_TEMPLATE_INTEREST,
      year5: MONTHLY3_TEMPLATE_INTEREST,
      month3: MONTHLY3_TEMPLATE_INTEREST
    }
    this.kalpService.setInterest(this.latestInterest)
    return EMPTY
  }


  /**
   * Get the latest valid interest from database, activate this when we should get interests from RT
   public getLatestInterest(): Observable<LatestInterest> {
   const url = `${environment.rtUrl}/interests/latest`
   return this.httpClient.get<LatestInterest>(url)
   .pipe(
   map((interest: LatestInterest) => {
   this.latestInterest = interest
   this.kalpService.setInterest(interest)
   return interest
   }),
   catchError(() => {
   this.snackBar.open('Ett fel har inträffat. Den senaste räntan är eventuellt inte korrekt.', 'Kontakta support', {
   horizontalPosition: 'center',
   verticalPosition: 'top'
   })
   return EMPTY
   })
   )
   }
   */

  public getThreeMonthInterestRate(): number {
    return +this.latestInterest.month3
  }

  public getInterestRates(): any {
    return new Map([
      [3, +this.latestInterest.month3],
      [12, +this.latestInterest.year1],
      [24, +this.latestInterest.year2],
      [36, +this.latestInterest.year3],
      [48, +this.latestInterest.year4],
      [60, +this.latestInterest.year5]
    ])
  }
}
