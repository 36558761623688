<div class="main">
  <spb-test type="APPLICATION"></spb-test>
  <div class="holder">
    <div class="content">
      <div class="row">
        @if (borgoService.borgoPossible$ | async) {
          <div>
            <div class="main-title">Borgo</div>
            <div [ngClass]="{ 'negative': borgo.kalp < 0 }"
                 class="kalp-number">{{ borgo.kalp | number: '1.0-0' : 'fr' }}
            </div>
          </div>
        }
        <div [ngClass]="{ 'right' : borgoService.borgoPossible$ | async }">
          <div class="main-title">Egen portfölj</div>
          <div
            [ngClass]="{ 'negative': sparbanken.kalp < 0 }"
            class="kalp-number">{{ sparbanken.kalp | number: '1.0-0' : 'fr' }}
          </div>
        </div>
      </div>
      <mat-tab-group class="row" mat-stretch-tabs>
        @if (borgoService.borgoPossible$ | async) {
          <mat-tab label="Borgo">
            <ng-template matTabContent>
              <spb-kalp-display [kalp]="borgo.kalp"
                                [kalpylator]="borgo"></spb-kalp-display>
            </ng-template>
          </mat-tab>
        }
        <mat-tab label="Egen portfölj">
          <ng-template matTabContent>
            <spb-kalp-display [kalp]="sparbanken.kalp" [kalpylator]="sparbanken"
                              title="Egen portfölj"></spb-kalp-display>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div class="row">
        @if (debtQuota !== null && debtQuota > maxDebtQuota && (borgoService.borgoPossible$ | async)) {
          <div
            class="warning">
            <p>Skuldkvoten överstiger {{ maxDebtQuota }} och krediten kan därför
              inte placeras hos Borgo när sökandes ålder
              är {{ age }} år.</p>
          </div>
        }
      </div>
      <div class="row">
        <div class="sliders mat-elevation-z4">
          <spb-slider
            (valueChange)="setIncome($event)"
            [initial]="sparbanken.monthlyIncome"
            [kalpylator]="sparbanken"
            [max]="500 * 1000"
            [min]="0"
            fieldTitle="Inkomst"
            suffix="kr/månad"
          ></spb-slider>
          <spb-slider
            (valueChange)="setTotalLoan($event)"
            [initial]="newLoan"
            [kalpylator]="sparbanken"
            [max]="newLoan * 5"
            [min]="10000"
            fieldTitle="Nya lån"
            suffix="kr"
          ></spb-slider>
          <spb-slider
            (valueChange)="calculate2p($event)"
            [kalpylator]="sparbanken"
            [max]="10"
            [min]="0"
            fieldTitle="Ökad ränta"
            suffix="%"
          ></spb-slider>
        </div>
      </div>
    </div>
    <div class="help">
      <ul>
        <li>KALP kan kopieras och klistras in i <strong>Excel</strong>.</li>
        <li>Klicka i KALP för att visa <strong>detaljnivå</strong>.</li>
        <li>Använd <strong>Reglage</strong> för att simulera kunds inkomst, sökt
          lånebelopp och räntehöjningar.
          Simuleringen kan återställas.
        </li>
        <li>Ska uppgifter korrigeras kan man gå <a (click)="goToIncome()"
                                                   [routerLink]="backDestination"
        ><strong>tillbaka</strong></a> i
          processen. Vid respektive steg finns en beräkna knapp som tar en fram
          till KALP igen.
        </li>
        @if (borgoService.borgoPossible$.value) {
          <li>Om det blir <strong>underskott</strong>
            i KALP visas även KALP resultat för om kredit läggs i
            egen portfölj
            för att få
            kännedom om ansökan kan beredas vidare.
          </li>
        }

        @if (borgoService.borgoPossible$.value) {
          <li>KALP beräknas enligt
            schablon för ränta och amortering. På övriga objekt räknas
            amortering
            med 1%, på belåningsobjekt räknas amortering enligt amorteringskrav
            0-3% alt alternativregeln. <br/>
            Ex: <br/>
            Kund har 85% belåning och skuldkvot över 4.5 - 3% <br/>
            Kund har 85% belåning och skuldkvot under 4.5 - 2% <br/>
            Kund har 5.000.000 som ej omfattas och ett Tilläggslån på 100.000 –
            <br/>
            Tilläggslån på 10 år.
          </li>
        }

        @if (!borgoService.borgoPossible$.value) {
          <li>KALP beräknas enligt
            schablon för ränta och amortering. Amortering räknas enligt
            amorteringskrav 0-3 % på samtliga objekt.
          </li>
        }
      </ul>
    </div>
  </div>
  <div class="buttons">
    <button [routerLink]="backDestination" mat-stroked-button>Tillbaka</button>
    <button (click)="reset()" color="primary" mat-raised-button>Börja om
    </button>
  </div>
</div>
