import {AsyncPipe} from '@angular/common'
import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {RouterLink} from '@angular/router'
import {START_ROUTE_PATH} from '../../application/data-types'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [RouterLink, MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, AsyncPipe]
})
export class HeaderComponent {
  constructor(
    public configService: ConfigService
  ) {
  }

  protected readonly START_ROUTE_PATH = START_ROUTE_PATH
}
