import {InjectionToken} from '@angular/core'

/**
 * Random variables used in the app
 */
export const KALP_ROUTE_PATH = 'kalp'
export const START_ROUTE_PATH = 'start'
export const LOGIN_ROUTE_PATH = 'login'
export const RESULT_ROUTE_PATH = 'result'
export const BK_USER_ROLE = 'bkAppUser'

export type THelpSection = 'pers-data' | 'income-cost' | 'new-home' | 'loan'
export const WINDOW = new InjectionToken<Window>('window')