import {AsyncPipe} from '@angular/common'
import {Component, Input, OnChanges, OnInit} from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormControlStatus,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatIconButton} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle
} from '@angular/material/datepicker'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {Loan, LoanType, Property} from '@sparbanken-syd/kalpylator'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {distinctUntilChanged, noop, ReplaySubject} from 'rxjs'
import {InterestService} from '../../../services/interest.service'
import {LoanTypeName, loanTypeNames} from '../application-types'

interface Term {
  interestPercent: number
  text: string
  terms: number
}

@Component({
  selector: 'spb-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatInput, MatSuffix, MatCheckbox, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatIconButton, MatIcon, AsyncPipe, FormatNumberDirective]
})
export class LoanComponent implements OnInit, OnChanges {

  @Input() housingLoan = false

  @Input() index = 0

  @Input() loan: Loan = {} as any

  /**
   * Show Borgo help or not
   */
  @Input() borgo: boolean | null = false

  /**
   * Properties to select from when setting loans
   */
  @Input() properties: Property[] = []

  /**
   * This should be input by the parent and will be set
   * to true when this form is valid
   */
  @Input() validControl: AbstractControl = new FormControl()

  /**
   * A method to remove a loan
   */
  @Input() removeLoan: ((index: number) => void) = noop

  /**
   * Properties to choose from
   */
  public propertySelect$ = new ReplaySubject<Property[]>(1)

  /**
   * The loan types to display, make a copy
   * so that we can modify it.
   */
  public loanTypes: LoanTypeName[] = loanTypeNames.map(l => l)

  /**
   * Latest interests from RT
   */
    // Needed in template
  public termsTexts: Term[] = [
    {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3},
    {interestPercent: 0, text: 'Bunden 1 år', terms: 12},
    {interestPercent: 0, text: 'Bunden 2 år', terms: 24},
    {interestPercent: 0, text: 'Bunden 3 år', terms: 36},
    {interestPercent: 0, text: 'Bunden 4 år', terms: 48},
    {interestPercent: 0, text: 'Bunden 5 år', terms: 60},
    {interestPercent: 0, text: 'Bunden 7 år', terms: 84},
    {interestPercent: 0, text: 'Bunden 10 år', terms: 120}
  ]

  public filteredTerms: Term[] = []

  public form = new FormGroup({
      amount: new FormControl<number | string>('',
        {
          nonNullable: true,
          validators: [Validators.required, Validators.min(1)]
        }),
      mortgage: new FormControl<string | number>('', {nonNullable: true}),
      interestPercentString: new FormControl<number>(-1, {nonNullable: true}),
      loanType: new FormControl<LoanType | null>(null, {validators: [Validators.required]}),
      propertyId: new FormControl<string>(''),
      additionalLoan: new FormControl<boolean>(false),
      solve: new FormControl<boolean>(false, {nonNullable: true}),
      expires: new FormControl<number>(0),
      terms: new FormControl<number>(0),
      showExtraSettings: new FormControl<boolean>(false)
    }
  )

  constructor(private interestService: InterestService) {
  }

  public changeTerm(months: number) {
    const interestRates = this.interestService.getInterestRates()
    const interestPercent = interestRates.get(months)

    if (interestPercent) {
      this.form.controls.interestPercentString.setValue(interestPercent)
    }
  }

  public ngOnInit(): void {
    this.form.valueChanges.subscribe({
      next: () => {
        Object.assign(this.loan,
          {
            type: 'loans',
            amount: this.form.controls.amount.value,
            interestRate: this.form.controls.interestPercentString.value / 100,
            loanType: this.form.controls.loanType.value,
            additionalLoan: this.form.controls.additionalLoan.value,
            propertyId: this.form.controls.propertyId.value,
            mortgage: this.form.controls.mortgage.value,
            solve: this.form.controls.solve.value,
            expires: new Date(this.form.controls.expires.value as number).getTime(),
            terms: this.form.controls.terms.value,
            showExtraSettings: this.form.controls.showExtraSettings.value
          })
      }
    })

    /**
     * If a belåningsobjekt exists, we can add extra loans to that property. It should have special id and be a new loan
     */
    this.form.controls.propertyId.valueChanges.pipe(
    ).subscribe(() => {
      if (this.form.controls.propertyId.value === 'new_property') {
        this.loan.id = 'new_property_extra'
        this.loan.new = true
      } else {
        this.loan.id = ''
        this.loan.new = false
      }
    })


    this.form.controls.loanType.valueChanges.subscribe({
      next: (value: LoanType | null) => {
        if (value === 'MORTGAGE') {
          this.form.controls.interestPercentString.setValue(this.interestService.getThreeMonthInterestRate())
        } else {
          // Schablon
          this.form.controls.interestPercentString.setValue((-1))
        }
      }
    })

    this.form.statusChanges.pipe(
      distinctUntilChanged()
    ).subscribe({
      next: (status: FormControlStatus) => {
        if (this.validControl) {
          this.validControl.setValue(status === 'VALID')
        }
      }
    })

    if (this.housingLoan) {
      this.form.controls.loanType.updateValueAndValidity()
    }
  }

  public showExtraSettings(showExtra: boolean | null) {

    //Reset
    if (showExtra) {
      this.form.controls.solve.setValue(false)
      this.form.controls.expires.setValue(0)
      this.form.controls.terms.setValue(0)
    }

    this.form.controls.showExtraSettings.setValue(!showExtra)
  }

  public ngOnChanges(): void {
    /**
     * Filter fixed interest rate in mortgages. If "borgo" is true, 7 years and 10 years should also be included
     */
    this.filteredTerms = this.borgo ? this.termsTexts : this.termsTexts
      .filter((term: Term) => term.terms !== 84 && term.terms !== 120)

    if (this.loan.mortgage === -1) {
      this.loan.mortgage = '' as unknown as number
    }
    this.form.controls.interestPercentString.setValue(
      Math.round(this.loan.interestRate * 100 * 100) / 100, {emitEvent: false})

    //Convert timestamp to date in template
    const loanWithExpire = this.loan as any

    if (loanWithExpire.expires) {
      loanWithExpire.expires = new Date(loanWithExpire.expires)
    }
    this.form.patchValue(loanWithExpire)

    /**
     * Filter and exclude all belåningsobjekt from the propertySelect list
     */
    this.propertySelect$.next(this.properties)

    // Stepper goes berserk if we do this on same cycle.
    setTimeout(() => this.validControl && this.validControl.setValue(this.form.status === 'VALID', 1))

    // Create a copy of loanTypeNames array
    const loanTypesCopy = loanTypeNames.map(loanType => ({...loanType}))

    // Filter loan types based on housing loan and borgo
    this.loanTypes = loanTypesCopy
      .filter(loanType => this.housingLoan ? loanType.type === 'MORTGAGE' : loanType.type !== 'MORTGAGE')
      .filter(loanType => {
        if (loanType.type === 'BLANCO' && !this.borgo) {
          loanType.title = 'Privatlån'
        }
        return this.borgo || loanType.type !== 'BORGEN'
      })
  }
}
