<ul>
  <li>Välj <strong>Pensionär</strong> om kunden gått i pension, detta för att
    kundens levnadskostnader ska reduceras i
    KALPen.
  </li>
  @if (borgoService.borgoPossible$ | async) {
    <li>Har kund gått i <strong>pension</strong> ska pensionsprognos inhämtas
      från www.minpension.se
    </li>
    <li>Välj om kund bor i <strong>storstad</strong> (kommun Malmö,
      Göteborg, Stockholm, detta för att
      kundens
      levnadskostnader
      ska beräknas i KALPen
    </li>
  }
  <li>Lägg till {{ (borgoService.borgoPossible$ | async) ? '' : 'antal' }}
    <strong>barn</strong>{{ (borgoService.borgoPossible$ | async) ? ' och ange ålder, ' : '.' }}
    {{ (borgoService.borgoPossible$ | async) ? 'lägg till flera om syskon finns.' : '' }}
  </li>
  <li>Lägg till <strong>bil</strong> om sådan finns, ange om den är privatägd
    eller leasad. Välj lägg till
    igen om mer än 1 bil finns.
  </li>
  <li>
    Nedskrivning av inkomst om kund är <strong>60+</strong> (Borgo) resp.
    <strong>62+</strong> (egen portfölj) och inte
    pensionär görs med automatik och framgår som information bland detaljer i
    KALP.
  </li>
</ul>
