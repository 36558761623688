<div [style.margin-top.px]="15">
  <div class="header">
    <div class="column"></div>
    <div class="column">Barn</div>
    <div class="column">Bil</div>
    <div class="column"></div>
    <div class="column center"></div>
  </div>

  <div class="row">
    <div class="column"></div>
    <div class="column">{{ this.kalpylator.monthlyChildrenCost }}</div>
    <div
      class="column">{{ this.kalpylator.monthlyCarCost | number: '1.0-0' : 'fr' }}
    </div>
    <div class="column"></div>
    <div class="column center">
      <spb-copy [copy]="copy"></spb-copy>
    </div>
  </div>
</div>
