import {Component, Input} from '@angular/core'
import {AbstractControl, FormControl, FormControlStatus} from '@angular/forms'

@Component({
  selector: 'spb-step',
  template: '',
  styleUrls: []
})
export class StepComponent {
  @Input() validControl: FormControl<boolean> = new FormControl<boolean>(false, {nonNullable: true})

  protected setValidator(control: AbstractControl): void {
    control.statusChanges.subscribe({
      next: (validity: FormControlStatus) => {
        this.validControl.setValue(validity === 'VALID')
      }
    })
  }
}
