import {AsyncPipe, DecimalPipe} from '@angular/common'
import {Component, inject, OnChanges} from '@angular/core'
import {BorgoService} from '../../../services/borgo.service'
import {KalpService, TDomains} from '../../../services/kalp.service'

@Component({
  selector: 'spb-new-home-help',
  templateUrl: './new-home-help.component.html',
  styleUrls: ['../help.scss'],
  imports: [DecimalPipe, AsyncPipe]
})
export class NewHomeHelpComponent implements OnChanges {
  public borgoService = inject(BorgoService)
  public villa = 0
  public bostadsratt = 0
  public fritidshus = 0
  public hyresratt = 0
  public fastighetsAvgift = 0

  constructor(private readonly kalpService: KalpService) {
    this.initializeValues('sparbanken')
  }

  ngOnChanges(): void {
    this.initializeValues(this.borgoService.borgoPossible$.value ? 'borgo' : 'sparbanken')
  }

  private initializeValues(borgoOrSparbanken: TDomains): void {
    this.villa = this.kalpService.getRunCost(borgoOrSparbanken)
    this.bostadsratt = this.kalpService.getRunCost(borgoOrSparbanken, 'BOSTADSRATT')
    this.fritidshus = this.kalpService.getRunCost(borgoOrSparbanken, 'FRITIDSHUS')
    this.hyresratt = this.kalpService.getRunCost(borgoOrSparbanken, 'HYRES')
    this.fastighetsAvgift = this.kalpService.getYearlyTax(borgoOrSparbanken)
  }
}
