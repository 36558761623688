<form [formGroup]="form">
  @for (incomeCost of incomeCostFormGroup; let i = $index; track i) {
    <div [formGroup]="incomeCost">
      @if (i < 1) {
        <div>Låntagare</div>
      } @else if (i > 0) {
        <div>Medlåntagare</div>
      }
      <div>
        <mat-form-field class="field">
          <mat-label>Inkomst före skatt</mat-label>
          <input formControlName="monthlyIncome" matInput name="inkomst"
                 spbFormatNumber type="text">
          <span matTextSuffix="">kr/månad</span>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="field">
          <mat-label>Ev. skattefri inkomst</mat-label>
          <input formControlName="taxFreeIncome" matInput
                 name="skattefriInkomst" spbFormatNumber type="text">
          <span matTextSuffix="">kr/månad</span>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="field">
          <mat-label>Studielån</mat-label>
          <input formControlName="studyLoan" matInput name="studielån"
                 spbFormatNumber type="text">
          <span matTextSuffix="">kr/månad</span>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="field">
          <mat-label>Övriga kostnader</mat-label>
          <input formControlName="otherCosts" matInput name="övrigaKostnader"
                 spbFormatNumber type="text">
          <span matTextSuffix="">kr/månad</span>
        </mat-form-field>
      </div>
    </div>
  }
</form>
