import {Component, Input} from '@angular/core'
import {THelpSection} from '../../../application/data-types'
import {PersDataHelpComponent} from '../1-pers-data/pers-data-help.component'
import {
  IncomeCostHelpComponent
} from '../2-income-cost/income-cost-help.component'
import {NewHomeHelpComponent} from '../3-new-home/new-home-help.component'
import {LoanHelpComponent} from '../4-loan/loan-help.component'

@Component({
  selector: 'spb-help-base',
  templateUrl: './help-base.component.html',
  styleUrls: ['./help-base.component.scss'],
  imports: [PersDataHelpComponent, IncomeCostHelpComponent, NewHomeHelpComponent, LoanHelpComponent]
})
export class HelpBaseComponent {
  @Input() section: THelpSection | null = null
}
