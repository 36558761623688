<ul>
  @if (borgoService.borgoPossible$ | async) {
    <li>Ange kundens befintliga lån i banken och i andra institut. Till skillnad
      mot andra krediter och
      borgensåtagande
      ska bolån som ska lösas <strong>ej</strong> anges.
    </li>

    <li>Ange kundens befintliga lån i banken och i andra institut. Ange ej de
      lån som ska lösas.
    </li>
  }

  <li><strong>Beräkna</strong> för att visa preliminär KALP.</li>
  <li>Amortering och ränta anges ej då dessa beräknas i KALP enligt
    schabloner.
  </li>
  <li>För att beräkna korrekta schabloner i KALP mm ska Bostad anges vid resp
    lån. Saknar du en bostad gå tillbaka
    till
    steget innan och lägg till information.
  </li>
</ul>
