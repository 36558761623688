import {DecimalPipe} from '@angular/common'
import {Component, Input} from '@angular/core'
import {Kalpylator} from '@sparbanken-syd/kalpylator'
import {CopyComponent} from '../../98-copy/copy.component'

@Component({
  selector: 'spb-lti',
  templateUrl: './lti.component.html',
  styleUrls: ['./lti.component.scss'],
  imports: [CopyComponent, DecimalPipe]
})
export class LtiComponent {

  @Input() public kalpylator: Kalpylator = new Kalpylator()

  public copy = (): string => {
    const kalp = this.kalpylator
    let res = ''
    res += `Belåningsgrad\t${this.kalpylator.loanDegree.toFixed(2)}\nAmorteringsgrundande skuld` +
      `\t${kalp.mortgageRequirementDebt}\nAmorteringsgrundande värde\t${kalp.mortgageRequirementValue}\nSkuldkvot` +
      `\t${kalp.debtQuota?.toFixed(2)}\nSkuldkvotsgrundande kreditbelopp\t${kalp.totalMortgageLoanAmount}` +
      `\nBruttoinkomst\t${kalp.annualTotalIncome}\n`.replace('.', ',')
    return res
  }
}
