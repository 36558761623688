<div class="table mat-elevation-z5">
  <div class="header">
    <div class="column mini">Belopp</div>
    <div class="column mini">Amortering</div>
    <div class="column mini">Ränta</div>
    <div class="column mini">Månadskostnad</div>
    <div class="center column mini">Belåningsobjekt</div>
  </div>
  @for (loan of this.kalpylator.loans; track loan) {
    <div class="row">
      <div class="column mini">{{ loan.amount | number: '1.0-0' : 'fr' }}</div>
      <div class="column mini">{{ loan.mortgage | number: '1.0-0' : 'fr' }}
      </div>
      <div
        class="column mini">{{ loan.monthlyInterest | number: '1.0-0' : 'fr' }}
      </div>
      <div class="column mini">{{ loan.monthlyCost | number: '1.0-0' : 'fr' }}
      </div>
      <div class="center column mini">
        @if (loan.new) {
          <mat-icon color="accent">check</mat-icon>
        }
      </div>
    </div>
  }
  <div class="summary">
    <div class="column sum mini">Totalt för Lån</div>
    <div
      class="column right mini">{{ kalpylator.monthlyLoanMortgage | number: '1.0-0' : 'fr' }}
    </div>
    <div
      class="column right mini">{{ kalpylator.monthlyLoanInterest | number: '1.0-0' : 'fr' }}
    </div>
    <div
      class="column right mini">{{ kalpylator.monthlyLoanCost | number: '1.0-0' : 'fr' }}
      <sup>*</sup></div>
    <div class="center column"></div>
    <div class="center column right">
      <spb-copy [copy]="copy"></spb-copy>
    </div>
  </div>
  <div><sup>*</sup>
    Avdraget {{ kalpylator.monthlyLoanTaxReturn | number:'1.0-0': 'fr' }}
    i skattereduktion.
  </div>
</div>
