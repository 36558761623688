<div [formGroup]="form" class="form">
  <div class="loan mat-elevation-z8">

    <div>
      <mat-form-field [style.width.px]="housingLoan ? 120 : 265">
        <mat-label>Typ av lån</mat-label>
        <mat-select formControlName="loanType" ngDefaultControl>
          @for (type of loanTypes; track type.type) {
            <mat-option
              [value]="type.type">{{ type.title }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field [style.width.px]="120">
        <mat-label>Belopp</mat-label>
        <input
          formControlName="amount"
          matInput
          name="loanAmount"
          spbFormatNumber>
        <span matTextSuffix="">kr</span>
      </mat-form-field>
    </div>

    @if (housingLoan) {
      <mat-checkbox
        formControlName="additionalLoan">Tilläggslån
      </mat-checkbox>
    }

    <!--    I have temporarily deactivated these because they are not needed at the moment.-->
    @if (!housingLoan && false) {
      <div>
        <mat-form-field [style.width.px]="130">
          <mat-label>Amortering</mat-label>
          <label>
            <input
              formControlName="mortgage"
              matInput
              spbFormatNumber
            >
          </label>
          <span matTextSuffix="">kr/mån</span>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field [style.width.px]="75">
          <mat-label>Ränta</mat-label>
          <label>
            <input
              class="spb-flipper"
              formControlName="interestPercentString"
              matInput
              type="number">
          </label>
          <span matTextSuffix="">%</span>
        </mat-form-field>
      </div>
    }
    @if (properties.length > 0 && form.controls.loanType.value === 'MORTGAGE') {
      <div>
        <mat-form-field
          [style.width.px]="250">
          <mat-label>Välj boende</mat-label>
          <mat-select formControlName="propertyId" ngDefaultControl>
            @for (property of (propertySelect$ | async); track property.id) {
              <mat-option [value]="property.id">{{ property.friendlyName }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }

    @if (form.controls.showExtraSettings.value) {
      <ng-container>

        <div>
          <mat-checkbox formControlName="solve">Löses</mat-checkbox>
        </div>

        <div>
          <mat-form-field [style.width.px]="175">
            <mat-label>Räntebindning</mat-label>
            <mat-select (selectionChange)="changeTerm($event.value)"
                        formControlName="terms" ngDefaultControl>
              @for (term of filteredTerms; track term.terms) {
                <mat-option [value]="term.terms">{{ term.text }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field [style.width.px]="175">
            <mat-label>Villkorsändringsdag</mat-label>
            <input [matDatepicker]="picker" formControlName="expires" matInput>
            <mat-datepicker-toggle [for]="picker"
                                   matIconSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
    }
    <div class="delete-button">
      <button (click)="removeLoan(index)" mat-icon-button>
        <mat-icon aria-hidden="false" aria-label="Ta bort" color="warn"
                  fontIcon="delete"></mat-icon>
      </button>
    </div>

    <!--    //TODO: Remove false when they want this function-->
    @if (housingLoan && false) {
      <div class="add-button">
        <button mat-icon-button
                (click)="showExtraSettings(form.controls.showExtraSettings.value)">
          <mat-icon aria-hidden="false" aria-label="Lägg till"
                    [fontIcon]="form.controls.showExtraSettings.value ? 'remove_circle_outline' : 'add_circle_outline'">

          </mat-icon>
        </button>
      </div>
    }
  </div>
</div>
