<ul>
  <li>Om kund har övriga inkomster (som får tillgodoräknas enligt Borgos
    regelverk) som inte beskattas som tjänst/näring
    anges dessa efter avräkning av ev. skatt i fältet för <strong>Ev skattefri
      inkomst</strong>. Hushållets övriga
    kostnader (som enligt
    Borgos regelverk ska tas upp) anges totalt i fältet för <strong>Övriga
      kostnader</strong>.
  </li>
  <li>Har kund <strong>Hyresintäkt</strong> och/eller <strong>A-kassa</strong>
    får dessa <strong>ej</strong> medräknas i
    KALP och ska <strong>ej</strong> anges.
  </li>
</ul>
