<div class="loan-holder">
  @for (loan of loans; let index = $index; track loan) {
    @if ((housingLoan && loan.loanType === 'MORTGAGE') || (!housingLoan && loan.loanType !== 'MORTGAGE')) {
      <spb-loan
        [borgo]="borgo"
        [index]="index"
        [loan]="loans[index]"
        [properties]="properties"
        [removeLoan]="removeLoan"
        [validControl]="validControls.at(index)"
        [housingLoan]="housingLoan"
      ></spb-loan>
    }
  }
  <button (click)="addLoan()" mat-stroked-button>
    {{
      housingLoan ? 'Lägg till bolån'
        : (borgo ? 'Lägg till andra krediter och borgensåtagande' : 'Lägg till privatlån, konto- & kortkrediter')
    }}
  </button>
</div>
