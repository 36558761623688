import {Clipboard} from '@angular/cdk/clipboard'
import {Component, Input} from '@angular/core'
import {MatIconButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatTooltip} from '@angular/material/tooltip'
import {noop} from 'rxjs'
import {environment} from '../../../environments/environment'

@Component({
  selector: 'spb-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss'],
  imports: [MatIconButton, MatTooltip, MatIcon]
})
export class CopyComponent {

  /**
   * The function to call to copy
   */
  @Input() copy: (() => string) = noop as any

  public testMode = environment.testMode

  constructor(
    private clipBoard: Clipboard
  ) {
  }

  public makeCopy(): void {
    this.clipBoard.copy(this.copy())
  }
}
