<div [formGroup]="form" class="form">
  <mat-form-field>
    @if (primaryProperty) {
      <mat-label>Bostad som belånas</mat-label>
    }
    @if (!primaryProperty) {
      <mat-label>Typ av bostad</mat-label>
    }
    <mat-select formControlName="propertyType" ngDefaultControl>
      @for (type of propertyTypes; track type.type) {
        <mat-option [value]="type.type">{{ type.title }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Drift</mat-label>
    <input formControlName="runCost" matInput name="drift"
           spbFormatNumber
           type="text">
    <span matTextSuffix="">kr/månad</span>
  </mat-form-field>

  @if (hasRent) {
    <mat-form-field>

      <mat-label>
        @if (propertyType === 'HYRES') {
          <span>Hyra</span>
        }

        @if (propertyType === 'BOSTADSRATT') {
          <span>Avgift</span>
        }
      </mat-label>
      <input formControlName="rent" matInput name="hyra"
             spbFormatNumber
             type="text">
      <span matTextSuffix="">kr/mån</span>
    </mat-form-field>
  }
  @if (hasRent) {
    <div class="spb-filler"></div>
  }

  @if (!property.primary) {
    <button (click)="removeProperty(index)"
            mat-icon-button>
      <mat-icon color="warn" fontIcon="delete"></mat-icon>
    </button>
  }
</div>
