import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatIconButton} from '@angular/material/button'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatSlider, MatSliderThumb} from '@angular/material/slider'
import {Kalpylator} from '@sparbanken-syd/kalpylator'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'

@Component({
  selector: 'spb-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  imports: [MatFormField, MatLabel, MatInput, ReactiveFormsModule, MatSuffix, MatSlider, MatSliderThumb, MatIconButton, MatIcon, FormatNumberDirective]
})
export class SliderComponent implements OnInit, OnChanges {
  /**
   * The top value, defaults to 100
   */
  @Input() max = 100

  /**
   * The minimum value, defaults to 0
   */
  @Input() min = 0

  /**
   * The start value
   */
  @Input() initial = 0

  /**
   * The title to show in the input field
   */
  @Input() fieldTitle = ''

  /**
   * The suffix to show in the input field
   */
  @Input() suffix = ''

  /**
   * This one I included just to get value changes...
   */
  @Input() kalpylator: Kalpylator = new Kalpylator()

  /**
   * Sen new values here.
   */
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>()

  public variableControl: FormControl<number> = new FormControl<number>(0, {nonNullable: true})

  /**
   * How much to step. Is calculated via min/max
   */
  public step = 1

  /**
   * Remember the start value so that we can reset
   */
  private initialValue = 0

  ngOnInit(): void {
    this.variableControl.valueChanges.subscribe({
      next: (value: number) => {
        this.valueChange.emit(value)
      }
    })
  }

  public ngOnChanges(): void {
    this.step = 1000
    if (this.max < 100) {
      this.step = 0.1
    }
    if (this.max > 1000 * 1000) {
      this.step = 10000
    }
    if (!this.kalpylator.monthlyIncomeOverride) {
      this.initialValue = this.initial
    }
    this.variableControl.setValue(this.initial, {emitEvent: false})

  }

  /**
   * Put back our initial value
   */
  public restore(): void {
    this.variableControl.setValue(this.initialValue)
  }

  /**
   * Whatever changed value we receive from the slider. We just
   * use it to set our control value and emit it to our "parent"
   */
  public change(value: number): void {
    this.variableControl.setValue(value, {emitEvent: false})
    this.valueChange.emit(value)
  }
}
