import {AsyncPipe, DecimalPipe} from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormControlStatus,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {Applicant, Car, Child} from '@sparbanken-syd/kalpylator'
import {pnrValidator} from '@sparbanken-syd/sparbanken-syd-theme'
import {startWith} from 'rxjs'
import {filter} from 'rxjs/operators'
import {
  RandomPnrComponent
} from '../../../common/random-pnr/random-pnr.component'
import {BorgoService} from '../../../services/borgo.service'
import {TypesService} from '../../../services/types.service'
import {StepComponent} from '../90-step/step.component'
import {PersonNummerSelect} from '../application-types'
import {CarsComponent} from './12-cars/cars.component'

@Component({
  selector: 'spb-pers-data',
  templateUrl: './pers-data.component.html',
  styleUrls: ['./pers-data.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, RandomPnrComponent, MatSuffix, MatCheckbox, MatButton, MatSelect, MatOption, MatIconButton, MatIcon, CarsComponent, AsyncPipe, DecimalPipe]
})
export class PersDataComponent extends StepComponent implements OnInit, OnChanges {

  @Input() public applicants: Applicant[] = []

  @Input() public children: Child[] = []

  @Input() public cars: Car[] = []
  /**
   * Simply emit the number of applicants
   */
  @Output() public applicantLength: EventEmitter<number> = new EventEmitter<number>()

  /**
   * Here we send the valid pnr of the primary applicant as soon as we get
   * a valid one.
   */
  @Output() public validPnr: EventEmitter<PersonNummerSelect> = new EventEmitter<PersonNummerSelect>()

  /**
   * List of applicants, cars and children
   */
  public persDatas = new FormArray<any>([])
  public childrenList = new FormArray<any>([])

  public childrenCountCrl = new FormControl<number>(0, {nonNullable: true})
  public coApplicantCtrl = new FormControl<boolean>(false, {nonNullable: true})

  /**
   * Selection range for how old a child is. Age 0 must be 0.1 to work (and then rounded in template).
   */
  public ages: number[] = [0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]


  constructor(
    private ts: TypesService,
    public borgoService: BorgoService
  ) {
    super()
  }

  get persDatasFormGroup(): FormGroup[] {
    return this.persDatas.controls as FormGroup[]
  }

  get childrenFormGroup(): FormGroup[] {
    return this.childrenList.controls as FormGroup[]
  }

  ngOnInit(): void {
    if (this.persDatasFormGroup.length === 0) {
      this.addApplicantToForm()
    }

    if (this.applicants.length === 0) {
      this.applicants.push(this.ts.getApplicant())
    }

    /**
     * This is used by the base class to know when we are all
     * set.
     */
    this.setValidator(this.persDatas)

    /**
     * Tell the world that we have 1 or two (or more) applicants.
     */
    this.applicantLength.emit(this.persDatasFormGroup.length)

    this.persDatas.valueChanges.pipe(
      startWith([{}] as any)
    ).subscribe({
      next: (val: Applicant[]) => {
        this.applicants.length = 0
        val.forEach((a: Applicant, index: number) => {
          this.applicants.push(this.ts.getApplicant())
          Object.assign(
            this.applicants[index], a)
        })
      }
    })

    this.childrenList.valueChanges.pipe(
      filter((c: Child[]) => c.length > 0)
    ).subscribe({
      next: (val: Child[]) => {
        this.children.length = 0
        val.forEach((p: Child, index: number) => {
          this.children.push(this.ts.getChild())
          Object.assign(this.children[index], p)
        })
        this.childrenCountCrl.setValue(this.children.length, {emitEvent: false})
      }
    })

    this.coApplicantCtrl.valueChanges.subscribe({
      next: (coApplicant: boolean) => {
        if (coApplicant) {
          this.addApplicantToForm()
        } else {
          this.applicants.pop()
          this.persDatasFormGroup.pop()
        }
        this.applicantLength.emit(this.persDatasFormGroup.length)
      }
    })

    this.childrenCountCrl.valueChanges.subscribe({
      next: (val: number) => {
        this.children.length = 0
        for (let i = 0; i < val; i++) {
          this.children.push({age: 0} as any)
        }
        this.ngOnChanges()
      }
    })

    this.persDatas.updateValueAndValidity()
  }

  public ngOnChanges(): void {
    while (this.persDatasFormGroup.length < this.applicants.length) {
      this.addApplicantToForm()
    }

    while (this.persDatasFormGroup.length > this.applicants.length) {
      this.persDatasFormGroup.pop()
    }


    this.coApplicantCtrl.setValue(this.applicants.length === 2, {emitEvent: false})
    this.persDatas.patchValue(this.applicants, {emitEvent: false})
    this.applicantLength.emit(this.persDatasFormGroup.length)

    while (this.childrenFormGroup.length < this.children.length) {
      this.addChildToForm()
    }

    while (this.childrenFormGroup.length > this.children.length) {
      this.childrenFormGroup.pop()
    }

    this.childrenList.patchValue(this.children)
    this.childrenCountCrl.setValue(this.children.length, {emitEvent: false})
    this.persDatas.updateValueAndValidity({emitEvent: true})
  }

  public isRetired(event: MatCheckboxChange, index: number): void {
    this.persDatasFormGroup[index].controls.occupationType.setValue(event.checked ? 'RETIRED' : 'EMPLOYED')
  }

  public addChildToForm(fromTemplate?: boolean): void {
    this.childrenList.push(new FormGroup({
      age: new FormControl<number>(0.1, {nonNullable: true})
    }), {emitEvent: false})
    this.childrenCountCrl.setValue(this.childrenList.length, {emitEvent: false})

    if (fromTemplate) {
      this.childrenList.patchValue(this.children)
    }
  }

  public deleteChildFromForm(index: number): void {
    this.childrenList.controls.splice(index, 1)
    this.children.splice(index, 1)
    this.childrenCountCrl.setValue(this.childrenList.length, {emitEvent: false})
  }

  private addApplicantToForm(): void {
    const subCtrl = new FormControl<string>('', {
      nonNullable: true, validators: [pnrValidator]
    })
    // Must bind the index or it will be looked up dynamically
    const index = this.persDatasFormGroup.length

    subCtrl.statusChanges
      .pipe(
        filter((state: FormControlStatus) => state === 'VALID')
      )
      .subscribe({
        next: () => {
          this.validPnr.emit({
            personNummer: subCtrl.value,
            index
          })
        }
      })

    this.persDatas.push(new FormGroup({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(4)]
      }),
      sub: subCtrl,
      occupationType: new FormControl<string>('EMPLOYED', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      retired: new FormControl<boolean>(false, {nonNullable: true}),
      cityDweller: new FormControl<boolean | null>(false)
    }), {emitEvent: false})
  }
}
