import {DecimalPipe, NgClass} from '@angular/common'
import {Component, Input} from '@angular/core'

@Component({
  selector: 'spb-kalp-item',
  templateUrl: './kalp-item.component.html',
  styleUrls: ['./kalp-item.component.scss'],
  imports: [NgClass, DecimalPipe]
})
export class KalpItemComponent {

  /**
   * The name to display for the value
   */
  @Input() label = ''

  /**
   * The value to display, will be formatted as number.
   */
  @Input() value = 0

  /**
   * A pretty optional index for the hosting component
   * to keep track of this item.
   */
  @Input() index = 0

  /**
   * If this is a sum rather than an item
   */
  @Input() summary = false

  /**
   * Put a + or minus before item?
   */
  @Input() plusMinus = ''

  @Input() decimals = '0'
}
