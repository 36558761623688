import {Component, Input, OnChanges, OnInit} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {Income} from '@sparbanken-syd/kalpylator'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {StepComponent} from '../90-step/step.component'

@Component({
  selector: 'spb-income-cost',
  templateUrl: './income-cost.component.html',
  styleUrls: ['./income-cost.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatSuffix, FormatNumberDirective]
})
export class IncomeCostComponent extends StepComponent implements OnInit, OnChanges {

  @Input() public incomes: Income[] = []

  /**
   * The form group for the applicants income and costs
   */
  public form = new FormGroup({
    incomeCost: new FormArray<any>([])
  })

  get incomeCostFormGroup(): FormGroup[] {
    return this.form.controls.incomeCost.controls as FormGroup[]
  }

  public ngOnInit(): void {
    /**
     * Set the thing that should emit when this component is valid
     */
    this.setValidator(this.form)

    this.form.controls.incomeCost.valueChanges.subscribe({
      next: (incomes: Income[]) => {
        incomes.forEach((i: Income, index: number) => Object.assign(this.incomes[index], i))
      }
    })
    this.form.updateValueAndValidity()
  }

  public ngOnChanges(): void {
    this.form.controls.incomeCost.clear()
    // Add a form for each
    this.incomes.forEach(() => this.addFormGroupToFormArray())
    // Patch in the data
    this.form.controls.incomeCost.patchValue(this.incomes)
  }


  private addFormGroupToFormArray(): void {
    this.form.controls.incomeCost.push(new FormGroup({
      monthlyIncome: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(100)]
      }),
      taxFreeIncome: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.min(0)]
      }),
      studyLoan: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.min(0)]
      }),
      otherCosts: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.min(0)]
      })
    }), {emitEvent: false})
  }
}
