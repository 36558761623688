<ul>
  <li>Ange <strong>faktisk driftskostnad</strong> om den
    överstiger {{ (borgoService.borgoPossible$ | async) ? 'Borgos' : 'bankens' }}
    schablon -
    villa {{ villa | number : '1.0-0' : 'fr' }},
    bostadsrätt {{ bostadsratt | number : '1.0-0' : 'fr' }},
    fritidshus {{ fritidshus | number : '1.0-0' : 'fr' }},
    hyresrätt {{ hyresratt | number : '1.0-0' : 'fr' }}
  </li>
  <li><strong>Fastighetsavgift</strong> beräknas per automatik i KALPen baserat
    på gällande regelverk, beräkning sker
    med högsta
    fastighetsavgift för villa och fritidshus
    (fn. {{ fastighetsAvgift | number : '1.0-0' : 'fr' }} kr /år)
  </li>
  @if (borgoService.borgoPossible$ | async) {

    <li>Avser kreditansökan flytt eller höjning av bolån där säkerheten är
      bostadsrätt ska <strong>lägenhetsförteckning</strong>
      inhämtas från förening
    </li>
    <li><strong>Försäkringsbolag</strong> ska inhämtas på låneobjekt, vid
      belåning av villa ska även uppgift om
      fullvärdesförsäkring och
      självrisk inhämtas
    </li>
  }
</ul>

