<div class="applicants">
  @for (persData of persDatasFormGroup; let i = $index; track i) {
    <div [formGroup]="persData" class="applicant">
      @if (i < 1) {
        <div class="margin-bottom-small">Låntagare</div>
      } @else if (i > 0) {
        <div class="margin-bottom-small">Medlåntagare</div>
      }
      <div class="person-nummer">
        <mat-form-field>
          <mat-label>Personnummer</mat-label>
          <input formControlName="sub" matInput type="text">
          <spb-random-pnr [control]="persData.controls.sub"
                          matSuffix></spb-random-pnr>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Namn</mat-label>
        <input formControlName="name" matInput type="text">
      </mat-form-field>
      <div>
        <mat-checkbox (change)="isRetired($event, i)" color="primary"
                      formControlName="retired" ngDefaultControl>Pensionär
        </mat-checkbox>
      </div>
      @if (borgoService.borgoPossible$ | async) {
        <div>
          <mat-checkbox color="primary" formControlName="cityDweller"
                        ngDefaultControl>Bor i storstad (kommun Malmö, Göteborg,
            Stockholm)
          </mat-checkbox>
        </div>
      }
    </div>
  }
</div>
<div [style.margin-bottom.px]="18">
  <mat-checkbox [formControl]="coApplicantCtrl" color="primary"
                ngDefaultControl>Lägg till medlåntagare
  </mat-checkbox>
</div>
<div [style.margin-bottom.px]="25">
  <button (click)="addChildToForm(true)" mat-stroked-button>Lägg till barn
  </button>
</div>
<div>
  @for (child of childrenFormGroup; let i = $index; track i) {
    <div [formGroup]="child" class="child">
      <mat-form-field>
        <mat-label>Ålder på barn</mat-label>
        <mat-select formControlName="age" ngDefaultControl>
          @for (age of ages; track age) {
            <mat-option [value]="age">{{ age | number: '1.0-0' }}</mat-option>
          }
        </mat-select>
        <button (click)="deleteChildFromForm(i)" color="warn" mat-icon-button
                matSuffix>
          <mat-icon aria-hidden="false" aria-label="Ta bort" color="warn"
                    fontIcon="delete"></mat-icon>
        </button>
      </mat-form-field>
    </div>
  }
</div>

<div [style.margin-top.px]="10">
  <spb-cars [cars]="cars"></spb-cars>
</div>
