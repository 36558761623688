@switch (section) {
  @case ('pers-data') {
    <spb-pers-data-help></spb-pers-data-help>
  }
  @case ('income-cost') {
    <spb-income-cost-help></spb-income-cost-help>
  }
  @case ('new-home') {
    <spb-new-home-help></spb-new-home-help>
  }
  @case ('loan') {
    <spb-loan-help></spb-loan-help>
  }
}
