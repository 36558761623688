import {Routes} from '@angular/router'
import {ApplicationComponent} from './application/application.component'

export default [
  {
    path: ':applicantId',
    component: ApplicationComponent
  },
  {
    path: ':applicantId/:coApplicantId',
    component: ApplicationComponent
  },
  {
    path: '**',
    component: ApplicationComponent
  }
] satisfies Routes