import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors
} from '@angular/forms'
import {MatIconButton} from '@angular/material/button'
import {MatOption} from '@angular/material/core'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {Property, PropertyType} from '@sparbanken-syd/kalpylator'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {noop, startWith} from 'rxjs'
import {BorgoService} from '../../../services/borgo.service'
import {KalpService, TDomains} from '../../../services/kalp.service'
import {TypesService} from '../../../services/types.service'
import {
  PropertyTypeName,
  propertyTypeNames,
  propertyTypeToName
} from '../application-types'

@Component({
  selector: 'spb-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatInput, MatSuffix, MatIconButton, MatIcon, FormatNumberDirective]
})
export class PropertyComponent implements OnInit, OnChanges {

  @Input() propertyTypes: PropertyTypeName[] = propertyTypeNames

  @Input() property: Property

  /**
   * Special rules apply for the main property, titles and stuff.
   */
  @Input() primaryProperty = false

  /**
   * Used only to be able to remove
   */
  @Input() index = 0

  /**
   * A method to remove a property
   */
  @Input() removeProperty: ((index: number) => void) = noop

  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>()

  public form = new FormGroup({
    propertyType: new FormControl<PropertyType>('VILLA', {nonNullable: true}),
    rent: new FormControl<number | string>('', {nonNullable: true}),
    runCost: new FormControl<number | string>('', {nonNullable: true})
  })

  public hasRent = false

  public propertyType: PropertyType = 'VILLA'

  private rentals: PropertyType[] = ['BOSTADSRATT', 'HYRES']

  constructor(
    private typeService: TypesService,
    private kalpService: KalpService,
    private borgoService: BorgoService
  ) {
    this.property = this.typeService.getProperty()
    this.form.controls.rent.addValidators([this.rentValidator])
  }

  public ngOnInit(): void {
    this.form.controls.propertyType.valueChanges.pipe(
    ).subscribe({
      next: (propertyType: PropertyType) => {
        this.property.propertyType = propertyType
        this.setDefaults()
      }
    })

    this.form.valueChanges.pipe(
      startWith({})
    ).subscribe({
      next: () => {
        Object.assign(this.property, this.form.getRawValue())
        if (this.property.primary) {
          this.property.friendlyName = 'Belåningsobjekt'
        } else {
          this.property.id = this.index + 'x'
          this.property.friendlyName =
            `Övrigt boende (${propertyTypeToName(this.property.propertyType)})`
        }
      }
    })

    this.form.statusChanges.pipe(startWith('INVALID'))
      .subscribe({
        next: () => {
          this.valid.emit(this.form.status === 'VALID')
        }
      })
  }

  public ngOnChanges() {
    this.form.patchValue(this.property)
    this.form.controls.propertyType.updateValueAndValidity()
  }

  private rentValidator = (control: AbstractControl): ValidationErrors | null => {
    if (this.hasRent && (control.value === '' || control.value === 0)) {
      return {
        rent: 'required'
      }
    }
    return null
  }

  private setDefaults(): void {
    const borgoOrSparbanken: TDomains = this.borgoService.borgoPossible$.value ? 'borgo' : 'sparbanken'
    this.kalpService.propertyDefaults(this.property, borgoOrSparbanken)
    this.form.controls.runCost.setValue(this.property.runCost as number, {emitEvent: false})
    this.hasRent = this.rentals.indexOf(this.property.propertyType) !== -1
    this.propertyType = this.property.propertyType
    this.form.controls.rent.setValue(this.property.rent as number)
  }

}
