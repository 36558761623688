<div class="table mat-elevation-z5">
  <div class="header">
    @if (type !== 'kalp-details') {
      <div class="column">Låntagare</div>
    }

    @if (type === 'income') {
      <ng-container>
        <div class="column">Inkomst</div>
        <div class="column">Skattefria inkomster</div>
        <div class="column">Barnbidrag</div>
      </ng-container>
    }

    @if (type === 'cost') {
      <ng-container>
        <div class="column">Skatt</div>
        <div class="column">Studielån</div>
        <div class="column">Övriga kostnader</div>
      </ng-container>
    }
    <div class="column center"></div>
  </div>
  @for (applicant of this.kalpylator.applicants; track applicant; let i = $index) {
    <div class="row">
      @if (type !== 'kalp-details') {
        <div class="column first-column">{{ applicant.name }}
          @if (applicant.reducedIncome) {
            <span><sup>*</sup></span>
          }
        </div>
      }
      @if (type === 'income') {
        <ng-container>
          <div
            class="column">{{ kalpylator.incomes[i].monthlyIncome | number: '1.0-0' : 'fr' }}
          </div>
          <div
            class="column">{{ kalpylator.incomes[i].taxFreeIncome | number: '1.0-0' : 'fr' }}
          </div>
          <div class="column">
            @if (i === 0) {
              <span>{{ kalpylator.monthlyChildrenIncome | number: '1.0-0' : 'fr' }}</span>
            } @else if (i > 0) {
              <span>-</span>
            }
          </div>
        </ng-container>
      }
      @if (type === 'cost') {
        <ng-container>
          <div
            class="column">{{ kalpylator.incomes[i].monthlyTax | number: '1.0-0' : 'fr' }}
          </div>
          <div
            class="column">{{ kalpylator.incomes[i].studyLoan | number: '1.0-0' : 'fr' }}
          </div>
          <div
            class="column">{{ kalpylator.incomes[i].otherCosts | number: '1.0-0' : 'fr' }}
          </div>
        </ng-container>
      }
      <div class="column center"></div>
    </div>
  }
  <div class="summary">
    @if (type !== 'kalp-details') {
      <div class="column first-column sum">
        Totalt
      </div>
    }
    @if (type === 'income') {
      <ng-container>
        <div
          class="column right">{{ kalpylator.monthlyIncome - kalpylator.monthlyTaxFreeIncome | number: '1.0-0' : 'fr' }}
        </div>
        <div
          class="column right">{{ kalpylator.monthlyTaxFreeIncome | number: '1.0-0' : 'fr' }}
        </div>
        <div
          class="column right">{{ kalpylator.monthlyChildrenIncome | number: '1.0-0' : 'fr' }}
        </div>
      </ng-container>
    }
    @if (type === 'cost') {
      <ng-container>
        <div
          class="column right">{{ kalpylator.monthlyIncomeTax | number: '1.0-0' : 'fr' }}
          <sup>*</sup></div>
        <div
          class="column right">{{ kalpylator.monthlyStudyLoan | number: '1.0-0' : 'fr' }}
        </div>
        <div
          class="column right">{{ kalpylator.monthlyOtherCosts | number: '1.0-0' : 'fr' }}
        </div>
      </ng-container>
    }

    @if (type === 'kalp-details') {
      <div>
        <div class="row">
          <div class="column" [style.min-width.px]="313">Kvar att leva på efter
            boendekostnad
          </div>
          <div class="column">{{
              kalpylator.kalp + kalpylator.monthlyLivingExpense + kalpylator.monthlyChildrenCost
                | number:'1.0-0':'fr'
            }} kr /månad
          </div>
          <div class="column center"></div>
        </div>

        <div class="row">
          <div class="column" [style.min-width.px]="313">
            Budget/levnadskostnader
          </div>
          <div
            class="column">{{ kalpylator.monthlyLivingExpense + kalpylator.monthlyChildrenCost | number:'1.0-0':'fr' }}
            kr /månad
          </div>
          <div class="column center"></div>
        </div>

        <div class="row">
          <div class="column" [style.min-width.px]="313">Överskott/Underskott
          </div>
          <div class="column lines">{{ kalpylator.kalp | number:'1.0-0':'fr' }}
            kr /månad
          </div>
          <div class="column center"></div>
        </div>
      </div>
    }
    <div class="column center">
    </div>
  </div>
  @if (type === 'cost') {
    <spb-other [kalpylator]="kalpylator"></spb-other>
  }
  <div class="copy-to-right">
    @if (type !== 'cost') {
      <spb-copy [copy]="copy"></spb-copy>
    }
  </div>
  @if (type === 'income' && kalpylator.reducedIncome) {
    <div><sup>*</sup>Sänkt
      inkomst på grund av 60+ och inte pensionär
    </div>
  }
  @if (type === 'cost' && kalpylator.incomes[0]) {
    <div><sup>*</sup>
      Inkomstskatt @if (kalpylator.incomes[1]) {
        <span>för huvudlåntagare</span>
      } beräknas genom att tillämpa
      skattetabell {{ kalpylator.incomes[0].taxTable | number:'1.0-0': 'fr' }}
      och
      skattekolumn {{ kalpylator.incomes[0].taxColumn }}.
      @if (type === 'cost' && kalpylator.incomes[1]) {
        <ng-container>
          För medlåntagare tillämpas
          skattetabell {{ kalpylator.incomes[1].taxTable | number:'1.0-0': 'fr' }}
          och
          skattekolumn {{ kalpylator.incomes[1].taxColumn }}.
        </ng-container>
      }
    </div>
  }
</div>
