import {AsyncPipe} from '@angular/common'
import {Component, inject} from '@angular/core'
import {BorgoService} from '../../../services/borgo.service'

@Component({
  selector: 'spb-loan-help',
  templateUrl: './loan-help.component.html',
  styleUrls: ['../help.scss'],
  imports: [AsyncPipe]
})
export class LoanHelpComponent {
  public borgoService = inject(BorgoService)

}
