<div matDialogTitle>Detaljer</div>
<mat-dialog-content>
  <h2 class="margin-top-small">KALP överskott/underskott</h2>
  <spb-applicants [kalpylator]="kalpylator"
                  type="kalp-details"></spb-applicants>
  <h2 class="margin-top-small">Inkomster</h2>
  <spb-applicants [kalpylator]="kalpylator" type="income"></spb-applicants>
  <h2 class="margin-top-small">Kostnader</h2>
  <spb-applicants [kalpylator]="kalpylator" type="cost"></spb-applicants>
  <h2 class="margin-top-small">Lån</h2>
  <spb-loans [kalpylator]="kalpylator"></spb-loans>
  <h2 class="margin-top-small">Boende</h2>
  <spb-properties [kalpylator]="kalpylator"></spb-properties>
  <h2 class="margin-top-small">Skuldkvot (LTI)</h2>
  <spb-lti [kalpylator]="kalpylator"></spb-lti>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button matDialogClose="null">Stäng</button>
</mat-dialog-actions>
